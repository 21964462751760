/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function TransparentFooter() {
    return (
        <footer className="footer">
            <Container>
                <div className="copyright" id="copyright">
                    <nav>
                        <ul>
                            <li>
                                <a>대표전화: 02-1234-1234</a>
                            </li>
                            <li>
                                <a>팩스: 02-1234-1234</a>
                            </li>
                            <li>
                                <a
                                    style={{
                                        textTransform: "none",
                                    }}
                                >
                                    이메일: email@email.com
                                </a>
                            </li>
                            <li>
                                <a>서울특별시 은평구 연서로 34번길 11-1</a>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <a
                                    style={{
                                        textTransform: "none",
                                    }}
                                >
                                    Copyright © {new Date().getFullYear()} Think
                                    Line AI. All Rights Reserved.
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </Container>
        </footer>
    );
}

export default TransparentFooter;
