/*eslint-disable*/
import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";
// core components

function IndexHeader() {
    let pageHeader = React.createRef();

    React.useEffect(() => {
        if (window.innerWidth > 991) {
            const updateScroll = () => {
                let windowScrollTop = window.pageYOffset / 3;
                pageHeader.current.style.transform =
                    "translate3d(0," + windowScrollTop + "px,0)";
            };
            window.addEventListener("scroll", updateScroll);
            return function cleanup() {
                window.removeEventListener("scroll", updateScroll);
            };
        }
    });

    return (
        <>
            <div className="page-header clear-filter" filter-color="grey" id="page-header">
                <div
                    className="page-header-image"
                    style={{
                        backgroundImage:
                            "url(" + require("assets/img/factory-header.jpg") + ")",
                    }}
                    ref={pageHeader}
                ></div>
                <Container>
                    <div className="content-center brand">
                        <h2>우리 회사에 꼭 맞는</h2><br></br>
                        <h2>스마트 팩토리 견적 받아보기</h2>
                        <br></br>

                        <h5>빠른 문의를 원한다면?  <Button style={{
                            color: "black",
                            backgroundColor: "white",
                        }}
                        onClick={() => {
                            window.location.href = "/question";
                        }}>문의하기</Button></h5> 
                    </div>
                </Container>
            </div>
        </>
    );
}

export default IndexHeader;
