import React from "react";
import ConsultingBlock1 from "components/ConsultingBlock/ConsultingBlock1";
import ConsultingBlock2 from "components/ConsultingBlock/ConsultingBlock2";
import ConsultingBlock3 from "components/ConsultingBlock/ConsultingBlock3";
import ConsultingBlock4 from "components/ConsultingBlock/ConsultingBlock4";
import ConsultingBlock5 from "components/ConsultingBlock/ConsultingBlock5";
import "./ConsultingSection.css";

const ConsultingSection = () => {
    return (
        <div>
            <div className="consulting-section" id="consulting-section">
                <ConsultingBlock1 />
                <ConsultingBlock2 />
                <ConsultingBlock3 />
                <ConsultingBlock4 />
                <ConsultingBlock5 />
            </div>
        </div>
    );
};

export default ConsultingSection;
