import React, { useEffect, useState } from "react";

import videoSource from "assets/about_video.mp4";

import "./About.css";

function About() {
    const [mode, setMode] = useState("big");
    const [windwoWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
            // 화면 너비가 1405px 미만인 경우 클래스를 변경
            if (819 < window.innerWidth && window.innerWidth < 1405) {
                setMode("medium");
            } else if (774 < window.innerWidth && window.innerWidth < 819) {
                setMode("small");
            } else if (774 > window.innerWidth) {
                setMode("low");
            } else {
                setMode("big");
            }
        }

        // 초기 로드 시와 화면 크기 변경 시에도 체크
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });
    return (
        <>
            <div
                className="video-container"
                data-background-color="black"
                id="about-section"
            >
                <video width={"100%"} loop autoPlay muted>
                    <source src={videoSource} type="video/mp4" />
                </video>
            </div>

            {windwoWidth > 774 ? (
                <div className={`about-text-${mode}`}>{aboutText()}</div>
            ) : (
                <div className="about-text-low">{aboutText()}</div>
            )}
        </>
    );
}

function aboutText() {
    return (
        <>
            <h3>Make It Happen</h3>
            <p>
                THINKLINE 은 제조, 공급망, 서비스 제공업체가 효율적으로 운영을
                계획, 관리, 최적화, 실행하여 오늘날 제조업체가 경험하는 가장
                어려운 상황을 해결할 수 있는 솔루션을 제공합니다.
            </p>
            <p>
                THINKLINE 은 가상 세계와 실제 세계를 연결하여 전 세계 고객이
                전략적 비즈니스 결과를 달성하기 위해 공급망, 제조, 물류,
                서비스를 협업, 모델링, 최적화, 실행할 수 있도록 지원합니다. 
            </p>
        </>
    );
}

export default About;
