import { limitText, priceToWon } from "services/customService.js";
import ProtoType from "prop-types";
import Product from "models/Product.js";

const AdminProductCard = ({ product }) => {
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",

                display: "inline-flex",
            }}
            onClick={() => {
                window.location.href = "/admin/index/" + product.id;
            }}
        >
            <div style={{ width: 172, height: 172, position: "relative" }}>
                <img
                    style={{
                        width: 172,
                        height: 172,
                        left: 0,
                        top: 0,
                        position: "absolute",
                    }}
                    src={product.imageUrl}
                    alt=""
                />
            </div>
            <div
                style={{
                    alignSelf: "stretch",
                    height: 79,
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 8,
                    display: "flex",
                }}
            >
                <div
                    style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 2,
                        display: "flex",
                        marginTop: "40px",
                    }}
                >
                    <div
                        style={{
                            color: "black",
                            fontSize: 16,
                            fontWeight: "700",
                            wordBreak: "break-all",
                        }}
                    >
                        {product.name}
                    </div>
                    <div
                        style={{
                            color: "#6D6D6D",
                            fontSize: 14,
                            fontWeight: "400",
                        }}
                    >
                        {limitText(product.description, 20)}
                    </div>
                </div>
                <div
                    style={{
                        color: "black",
                        fontSize: 16,
                        fontWeight: "700",
                        wordWrap: "break-word",
                    }}
                >
                    {priceToWon(product.price)}
                </div>
            </div>
        </div>
    );
};

AdminProductCard.defaultProps = {

};

AdminProductCard.defaultProps.protoTypes = {
    product: ProtoType.instanceOf(Product).isRequired,
};

export default AdminProductCard;
