import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import * as ChannelService from "@channel.io/channel-web-sdk-loader";

import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/css/now-ui-kit.css";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";

import Index from "./views/Index.js";
import SignIn from "./views/SignIn.js";

import AdminSignIn from "views/admin/AdminSignIn.js";
import AdminIndex from "views/admin/AdminIndex.js";
import Shop from "views/shop/Shop.js";
import ShopDetail from "views/shop/ShopDetail.js";
import AdminShopDetail from "views/admin/AdminShopDetail.js";
import AdminShopCreate from "views/admin/AdminShopCreate.js";
import Question from "views/question/Question.js";
import CreateQuestion from "views/question/CreateQuestion/CreateQuestion.js";
import QuestionDetail from "views/question/QuestionDetail.js";
import Consulting from "views/consulting/Consulting.js";
import Privacy from "Privacy.js";
import AdminQuestion from "views/admin/question/AdminQuestion.js";
import ReplyQuestion from "views/admin/question/ReplyQuestion.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

ChannelService.loadScript();

ChannelService.boot({
    pluginKey: "8d44d6c8-42db-44cf-8014-60eddf3ae870", // fill your plugin key
});
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/index/:id" element={<Index />} />
            <Route path="/sign-in" element={<SignIn />} />
            {/* <Route path="/sign-up" element={<SignUp />} /> */}
            <Route path="/shop" element={<Shop />} />
            <Route path="/shop/:id" element={<ShopDetail />} />
            {/* <Route path="/shop/cart" element={<Cart />} /> */}
            <Route path="/consulting/:id" element={<Consulting />} />
            <Route path="/question" element={<Question />} />
            <Route path="/question/index/:id" element={<QuestionDetail />} />
            <Route path="/question/create" element={<CreateQuestion />} />
            <Route path="/admin" element={<AdminSignIn />} />
            <Route path="/admin/index/:id" element={<AdminShopDetail />} />
            <Route path="/admin/index" element={<AdminIndex />} />
            <Route path="/admin/create" element={<AdminShopCreate />} />
            <Route path="/admin/question" element={<AdminQuestion />} />
            <Route path="/admin/question/index/:id" element={<ReplyQuestion />} />
            <Route exact path="/privacy" element={<Privacy />} />
            <Route
                path="*"
                element={<Navigate to="/index/page-header" replace />}
            />
        </Routes>
    </BrowserRouter>
);
