import { memo, useEffect, useState } from "react";
import { doc, setDoc } from "firebase/firestore";

import { firestore } from "config/firebase";
import Loading from "components/Loading/Loading";
import Board from "models/Board";
import { useParams } from "react-router-dom";
import { getBoardFromFirestoreAtAdmin } from "services/boardService";
import AdminDepthNavbar2 from "components/Navbars/AdminDepthNavbar2";
import { sendAnswerMail } from "services/emailService";
import BannerHeader from "views/index-sections/BannerHeader";

import "./AdminQuestion.css";
import { deleteBoardFromFirestore } from "services/boardService";

const ReplyQuestion = memo(() => {
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();
    const [board, setBoard] = useState(Board.fromEmpty());
    const [reply, setReply] = useState("");

    useEffect(() => {
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");

        const fetchData = async () => {
            try {
                const boardData = await getBoardFromFirestoreAtAdmin(id);
                setBoard(boardData);
                setReply(boardData.reply);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching product: ", error);
            }
        };
        fetchData();
    }, [id]);

    const handleReplyChange = (event) => {
        setReply(event.target.value);
    };

    const updateToFirebase = async () => {
        setIsLoading(true);

        if (reply === "") {
            alert("답변을 입력하세요.");
            setIsLoading(false);
            return;
        }

        const db = firestore;

        const boardData = Board.fromSet(
            board.id,
            board.title,
            board.writer,
            board.phone,
            board.email,
            board.content,
            board.date,
            "답변 완료",
            board.password,
            reply
        );

        const docRef = doc(db, "boards", boardData.id);
        sendAnswerMail(
            board.email,
            board.writer,
            "THINKLINE.AI",
            reply,
            `문의제목:  [${board.title}]\n\n문의사항(상세):  ${board.content}\n\n연락처:  ${board.phone}\n\nEmail:  ${board.email} \n\n문의일시:  ${board.date}}`
        );
        try {
            await setDoc(docRef, boardData.toFirestore());
            window.history.back();
            alert("문의사항이 등록되었습니다.");
        } catch (e) {
            setIsLoading(false);
            alert(e.message);
            alert("데이터 생성 중 에러가 발생했습니다.");
        }
    };
    return (
        <>
            <AdminDepthNavbar2 />
            <BannerHeader
                title="답변하기"
                description="답변이 이메일로 전송됩니다."
            />
            {isLoading && (
                <div className="admin-question-modal">
                    <Loading />
                </div>
            )}

            <div className={`admin-question-createFormBody`}>
                <div className="admin-question-create-form">
                    <div className="admin-question-input-section">
                        <div className="admin-question-parent">
                            <b className="admin-question-b">제목</b>
                            <p>
                                <textarea
                                    className="admin-question-frame-child"
                                    type="text"
                                    value={board.title}
                                    readOnly
                                />
                            </p>
                        </div>
                        <div className="admin-question-parent">
                            <b className="admin-question-b">기업정보</b>
                            <p>
                                <textarea
                                    className="admin-question-frame-item"
                                    type="text"
                                    value={board.companyInfo}
                                    readOnly
                                />
                            </p>
                        </div>
                        <div className="admin-question-parent">
                            <b className="admin-question-b">문의사항 상세</b>
                            <p>
                                <textarea
                                    className="admin-question-frame-item"
                                    type="text"
                                    value={board.content}
                                    readOnly
                                />
                            </p>
                        </div>
                        <div className="admin-question-parent">
                            <b className="admin-question-b">담당자 성함</b>
                            <p>
                                <textarea
                                    className="admin-question-frame-child"
                                    type="text"
                                    value={board.writer}
                                    readOnly
                                />
                            </p>
                        </div>
                        <div className="admin-question-parent">
                            <b className="admin-question-b">연락처</b>
                            <p>
                                <textarea
                                    className="admin-question-frame-child"
                                    type="text"
                                    value={board.phone}
                                    readOnly
                                />
                            </p>
                        </div>
                        <div className="admin-question-parent">
                            <b className="admin-question-b">이메일</b>
                            <p>
                                <textarea
                                    className="admin-question-frame-child"
                                    type="text"
                                    value={board.email}
                                    readOnly
                                />
                            </p>
                        </div>
                        <div className="admin-question-parent">
                            <b className="admin-question-b">작성날짜</b>
                            <p>
                                <textarea
                                    className="admin-question-frame-child"
                                    type="text"
                                    value={board.date}
                                    readOnly
                                />
                            </p>
                        </div>
                        <div className="admin-question-parent">
                            <b className="admin-question-b">답변</b>
                            <p>
                                <textarea
                                    className="admin-question-frame-item"
                                    placeholder="답변 대기중"
                                    type="text"
                                    value={reply}
                                    onChange={handleReplyChange}
                                />
                            </p>
                        </div>
                    </div>
                    <div className="admin-reply-button-div">
                        <button
                            className="admin-reply-save-button"
                            onClick={async () => {
                                await updateToFirebase();
                            }}
                        >
                            <b className="admin-question-save-button-text">
                                답변 저장
                            </b>
                        </button>
                    </div>
                    <button
                        className="question-detail-delete-button"
                        onClick={async () => {
                            await remove(board.id);
                        }}
                    >
                        삭제
                    </button>
                </div>
            </div>
        </>
    );
});

const remove = async (id) => {
    if (
        window.confirm(
            "해당 게시물이 삭제되면 다시 복구할 수 없습니다.\n정말 삭제하시겠습니까?"
        )
    ) {
        await deleteBoardFromFirestore(id);
        window.history.back();
        alert("삭제되었습니다.");
    }
};

export default ReplyQuestion;
