import { firestore } from "config/firebase";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import Product from "models/Product";

export function getProductList() {
    const productData = [];

    for (let i = 0; i < 10; i++) {
        productData.push(
            new Product(
                i.toString() + "000",
                "Test Product",
                100000 + i * 10000 + i * 1000 + i * 100 + i * 10 + i,
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
                "1",
                "https://picsum.photos/200/300"
            )
        );
    }

    return productData;
}

export async function getProductListFromFirestore() {
    const productData = [];
    const querySnapshot = await getDocs(collection(firestore, "products"));

    querySnapshot.forEach((doc) => {
        // Firestore 문서에서 데이터 추출
        const data = doc.data();

        // Product 클래스로 변환
        const product = new Product(
            doc.id,
            data.name,
            data.price,
            data.description,
            data.type,
            data.imageUrl
        );

        productData.push(product);
    });

    return productData;
}

export async function getProductFromFirestore(id) {
    const snapshot = await getDoc(doc(firestore, "products", id));

    console.log(snapshot);

    if (snapshot.exists()) {
        const data = snapshot.data();

        return new Product(
            snapshot.id,
            data.name,
            data.price,
            data.description,
            data.type,
            data.imageUrl
        );
    } else {
        return null; // 문서가 존재하지 않을 때 처리
    }
}

export function getProduct(id) {
    const value = getProductList().find((product) => product.id === id);

    return new Product(
        value.id,
        value.name,
        value.price,
        value.description,
        value.type,
        value.imageUrl
    );
}
