/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const LineRoundedSearch7 = ({ color = "white", className }) => {
  return (
    <svg
      className={`line-rounded-search-7 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 15 16"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_1654_1618)">
        <path
          className="path"
          d="M6.80559 12.9449C9.87384 12.9449 12.3611 10.2917 12.3611 7.01895C12.3611 3.74615 9.87384 1.09302 6.80559 1.09302C3.73734 1.09302 1.25003 3.74615 1.25003 7.01895C1.25003 10.2917 3.73734 12.9449 6.80559 12.9449Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          className="path"
          d="M13.7502 14.4263L10.7294 11.2041"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_1654_1618">
          <rect className="rect" fill="white" height="16" width="15" />
        </clipPath>
      </defs>
    </svg>
  );
};

LineRoundedSearch7.propTypes = {
  color: PropTypes.string,
};
