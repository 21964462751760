import { v4 as uuidv4 } from "uuid";
class Product {
    constructor(id, name, price, description, type, imageUrl) {
        this.id = id;
        this.name = name;
        this.price = price;
        this.description = description;
        this.type = type;
        this.imageUrl = imageUrl;
    }

    // 객체를 JSON 문자열로 직렬화
    toJson() {
        return JSON.stringify(this);
    }

    toFirestore() {
        return {
            id: this.id,
            name: this.name,
            price: this.price,
            description: this.description,
            type: this.type,
            imageUrl: this.imageUrl,
        };
    }

    // JSON 문자열을 객체로 역직렬화
    static fromJson(json) {
        const { id, name, price, description, type, imageUrl } =
            JSON.parse(json);
        return new Product(id, name, price, description, type, imageUrl);
    }

    static fromEmpty() {
        return new Product(uuidv4(), "", 0, "", "");
    }

    static fromNew(name, price, description, type, imageUrl) {
        return new Product(uuidv4(), name, price, description, type, imageUrl);
    }

    static fromSet(id, name, price, description, type, imageUrl) {
        return new Product(id, name, price, description, type, imageUrl);
    }
}

export default Product;
