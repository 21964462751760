import React, { memo, useState } from "react";

import "./Question.css";
import { deleteBoardFromFirestore } from "services/boardService";

const BoardDetail = memo(({ board }) => {
    const [password, setPassword] = useState("");
    const [showContent, setShowContent] = useState(false);
    const [error, setError] = useState("");

    const [responsive, setResponsive] = useState("");

    React.useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 580) {
                setResponsive("responsive");
            } else {
                setResponsive("");
            }
        }
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handlePasswordSubmit = () => {
        if (password === board.password) {
            setShowContent(true);
            setError("");
        } else {
            setShowContent(false);
            setError("비밀번호가 일치하지 않습니다.");
        }
    };

    return (
        <>
            {!showContent &&
                passwordCheck(
                    password,
                    setPassword,
                    handlePasswordSubmit,
                    error
                )}
            {showContent && boardDetailBody(board, responsive)}
        </>
    );
});

export default BoardDetail;

export function boardDetailBody(board, responsive) {
    return (
        <div className={`question-detail-createFormBody`}>
            <div className="question-detail-create-form">
                <div className="question-detail-input-section">
                    <div className={`question-detail-parent${responsive}`}>
                        <b className="question-detail-b">제목</b>
                        <p>
                            <textarea
                                className={`question-detail-frame-child${responsive}`}
                                type="text"
                                value={board.title}
                                readOnly
                            />
                        </p>
                    </div>
                    <div className={`question-detail-parent${responsive}`}>
                        <b className="question-detail-b">기업정보</b>
                        <p>
                            <textarea
                                className={`question-detail-frame-item${responsive}`}
                                type="text"
                                value={board.companyInfo}
                                readOnly
                            />
                        </p>
                    </div>
                    <div className={`question-detail-parent${responsive}`}>
                        <b className="question-detail-b">문의사항 상세</b>
                        <p>
                            <textarea
                                className={`question-detail-frame-item${responsive}`}
                                type="text"
                                value={board.content}
                                readOnly
                            />
                        </p>
                    </div>
                    <div className={`question-detail-parent${responsive}`}>
                        <b className="question-detail-b">담당자 성함</b>
                        <p>
                            <textarea
                                className={`question-detail-frame-child${responsive}`}
                                type="text"
                                value={board.writer}
                                readOnly
                            />
                        </p>
                    </div>
                    <div className={`question-detail-parent${responsive}`}>
                        <b className="question-detail-b">연락처</b>
                        <p>
                            <textarea
                                className={`question-detail-frame-child${responsive}`}
                                type="text"
                                value={board.phone}
                                readOnly
                            />
                        </p>
                    </div>
                    <div className={`question-detail-parent${responsive}`}>
                        <b className="question-detail-b">이메일</b>
                        <p>
                            <textarea
                                className={`question-detail-frame-child${responsive}`}
                                type="text"
                                value={board.email}
                                readOnly
                            />
                        </p>
                    </div>
                    <div className={`question-detail-parent${responsive}`}>
                        <b className="question-detail-b">작성날짜</b>
                        <p>
                            <textarea
                                className={`question-detail-frame-child${responsive}`}
                                type="text"
                                value={board.date}
                                readOnly
                            />
                        </p>
                    </div>
                    <div className={`question-detail-parent${responsive}`}>
                        <b className="question-detail-b">답변</b>
                        <p>
                            <textarea
                                className={`question-detail-frame-item${responsive}`}
                                placeholder="답변 대기중"
                                type="text"
                                value={board.reply}
                                readOnly
                            />
                        </p>
                    </div>
                    <button
                        className="question-detail-delete-button"
                        onClick={async () => {
                            await remove(board.id);
                        }}
                    >
                        삭제
                    </button>
                </div>
            </div>
        </div>
    );
}
const remove = async (id) => {
    if (window.confirm("삭제하시겠습니까?")) {
        await deleteBoardFromFirestore(id);
        window.history.back();
        alert("삭제되었습니다.");
    }
};

function passwordCheck(password, setPassword, handlePasswordSubmit, error) {
    return (
        <div className="question-detail-password-check">
            <h3>비공개 게시물 입니다.</h3>
            <h4>작성자만 내용을 확인할 수 있습니다.</h4>
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button onClick={handlePasswordSubmit}>확인</button>
            {error && <p>{error}</p>}
        </div>
    );
}
