import React from "react";

import {
    Collapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
} from "reactstrap";

import favicon from "assets/img/favicon.png";

function IndexNavbar() {
    const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    React.useEffect(() => {
        const updateNavbarColor = () => {
            if (
                document.documentElement.scrollTop > 399 ||
                document.body.scrollTop > 399
            ) {
                setNavbarColor("navbar-black");
            } else if (
                document.documentElement.scrollTop < 400 ||
                document.body.scrollTop < 400
            ) {
                setNavbarColor("navbar-transparent");
            }
        };
        window.addEventListener("scroll", updateNavbarColor);
        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
        };
    });

    return (
        <>
            {collapseOpen ? (
                <div
                    id="bodyClick"
                    onClick={() => {
                        toggleCollapse(setCollapseOpen, false);
                    }}
                />
            ) : null}
            <Navbar
                className={"fixed-top " + navbarColor}
                expand="lg"
                color="info"
            >
                <Container>
                    <div className="navbar-translate">
                        <NavbarBrand
                            href="/index"
                            id="navbar-brand"
                            style={{ fontSize: "1.5rem" }}
                        >
                            <h6>
                                <img src={favicon} alt="Logo" /> ThinkLine.AI
                            </h6>
                        </NavbarBrand>
                        <button
                            className="navbar-toggler navbar-toggler"
                            onClick={() => {
                                toggleCollapse(setCollapseOpen, !collapseOpen);
                            }}
                            aria-expanded={collapseOpen}
                            type="button"
                        >
                            <span className="navbar-toggler-bar top-bar"></span>
                            <span className="navbar-toggler-bar middle-bar"></span>
                            <span className="navbar-toggler-bar bottom-bar"></span>
                        </button>
                    </div>
                    <Collapse
                        className="justify-content-end"
                        isOpen={collapseOpen}
                        navbar
                    >
                        <Nav navbar style={{ fontSize: "1.2rem" }}>
                            <NavItem>
                                <NavLink
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const scroll = () => {
                                            document
                                                .getElementById("page-header")
                                                .scrollIntoView({
                                                    behavior: "smooth",
                                                });
                                        };
                                        requestAnimationFrame(scroll);
                                        toggleCollapse(
                                            setCollapseOpen,
                                            !collapseOpen
                                        );
                                    }}
                                >
                                    <p>Home</p>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const scroll = () => {
                                            document
                                                .getElementById("about-section")
                                                .scrollIntoView({
                                                    behavior: "smooth",
                                                });
                                        };
                                        requestAnimationFrame(scroll);
                                        toggleCollapse(
                                            setCollapseOpen,
                                            !collapseOpen
                                        );
                                    }}
                                >
                                    <p>Who We Are</p>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    onClick={(e) => {
                                        window.location.href = "/consulting/1";
                                    }}
                                >
                                    <p>Consulting</p>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    onClick={(e) => {
                                        window.location.href = "/shop";
                                    }}
                                >
                                    <p>Store</p>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    onClick={(e) => {
                                        window.location.href = "/question";
                                    }}
                                >
                                    <p>Q&A</p>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </>
    );
}

function toggleCollapse(toggle, value) {
    document.documentElement.classList.toggle("nav-open");
    toggle(value);
}

export default IndexNavbar;
