import ProductCard from "components/Card/ProductCard";
import React, { useEffect, useState } from "react";
import { List } from "react-content-loader";
import { Col, Row } from "reactstrap";
import { getProductListFromFirestore } from "services/productService";

const ProductList = () => {
    const [products, setProducts] = useState([]);
    const [columnSize, setColumnSize] = useState("3");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const productData = await getProductListFromFirestore();
                setProducts(productData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching products: ", error);
            }
        };

        fetchProducts();
    }, []);

    useEffect(() => {
        // Function to update column size based on window width
        const updateColumnSize = () => {
            if (window.innerWidth < 994) {
                setColumnSize("4");
            } else {
                setColumnSize("3");
            }
        };

        // Add event listener for window resize
        window.addEventListener("resize", updateColumnSize);

        // Initial call to set the column size
        updateColumnSize();

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", updateColumnSize);
        };
    }, []);
    return (
        <>
            <div>
                {loading ? (
                    <List />
                ) : (
                    <Row>
                        {products.map((product) => {
                            return (
                                <Col
                                    key={product.id}
                                    md={columnSize}
                                    style={{
                                        marginBottom: "50px",
                                    }}
                                >
                                    <ProductCard product={product} />
                                </Col>
                            );
                        })}
                    </Row>
                )}
            </div>
        </>
    );
};

export default ProductList;
