import React, { useEffect } from "react";
import { Container } from "reactstrap";
import ProductList from "./ProductList";

const ShopList = () => {
    useEffect(() => {
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
    }, []);
    return (
        <Container
            style={{
                marginTop: "50px",
            }}
        >
            <ProductList />
        </Container>
    );
};

export default React.memo(ShopList);
