import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DepthNavbar from "components/Navbars/DepthNavbar";
import Frame from "components/Frame/Frame";
import { getProductFromFirestore } from "services/productService";
import { Instagram } from "react-content-loader";
import Product from "models/Product";
import ProductList from "./ProductList";

import "./Shop.css";
import { Container } from "reactstrap";
import { Divider } from "@mui/material";

const ShopDetail = () => {
    const { id } = useParams();
    const [product, setProduct] = useState(Product.fromEmpty());
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");

        const fetchData = async () => {
            try {
                const productData = await getProductFromFirestore(id);
                setProduct(productData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching product: ", error);
            }
        };

        fetchData();
    }, [id]);
    return (
        <>
            <DepthNavbar />
            <div className="shopDetailDivWrapper">
                <div style={{ height: "150px" }}></div>

                {loading ? <Instagram /> : <Frame product={product} />}
                <Container
                    style={{
                        marginTop: "100px",
                    }}
                ></Container>
            </div>

            <div className="otherProducts">
                <div className="otherProductsTextWrapper">다른 제품들</div>
                <div className="otherProductsDivider">
                    <Divider
                        sx={{
                            borderBottom: "1px solid black",
                        }}
                    />
                </div>

                <ProductList />
            </div>
        </>
    );
};

export default ShopDetail;
