import { Container } from "reactstrap";

function BannerHeader(props) {
    return (
        <>
            <div style={{ height: "80px" }}></div>
            <div
                className="page-header-image"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    height: "148px",
                    backgroundImage:
                        "url(" + require("assets/img/banner.jpg") + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            >
                <Container>
                    <div
                        style={{
                            color: "white",
                            fontSize: 32,
                            fontWeight: "700",
                            wordWrap: "break-word",
                        }}
                    >
                        {props.title}
                    </div>
                    <div
                        style={{
                            color: "white",
                            fontSize: 16,
                            fontWeight: "400",
                            wordWrap: "break-word",
                        }}
                    >
                        {props.description}
                    </div>
                </Container>
            </div>
        </>
    );
}

export default BannerHeader;
