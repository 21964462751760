import { v4 as uuidv4 } from "uuid";

class Board {
    constructor(
        id,
        title,
        companyInfo,
        writer,
        phone,
        email,
        content,
        date,
        status,
        password,
        reply
    ) {
        this.id = id;
        this.title = title;
        this.companyInfo = companyInfo;
        this.writer = writer;
        this.phone = phone;
        this.email = email;
        this.content = content;
        this.date = date;
        this.status = status;
        this.password = password;
        this.reply = reply;
    }

    // 객체를 JSON 문자열로 직렬화
    toJson() {
        return JSON.stringify(this);
    }

    toFirestore() {
        return {
            id: this.id,
            title: this.title,
            companyInfo: this.companyInfo,
            writer: this.writer,
            phone: this.phone,
            email: this.email,
            content: this.content,
            date: this.date,
            status: this.status,
            password: this.password,
            reply: this.reply,
        };
    }

    // JSON 문자열을 객체로 역직렬화
    static fromJson(json) {
        const {
            id,
            title,
            companyInfo,
            writer,
            phone,
            email,
            content,
            date,
            status,
            password,
            reply,
        } = JSON.parse(json);
        return new Board(
            id,
            title,
            companyInfo,
            writer,
            phone,
            email,
            content,
            date,
            status,
            password,
            reply
        );
    }

    static fromEmpty() {
        return new Board(uuidv4(), "", "", "", "", "", "", "", "", "", "");
    }

    static fromNew(
        title,
        companyInfo,
        writer,
        phone,
        email,
        content,
        date,
        status,
        password,
        reply
    ) {
        return new Board(
            uuidv4(),
            title,
            companyInfo,
            writer,
            phone,
            email,
            content,
            date,
            status,
            password,
            reply
        );
    }

    static fromSet(
        id,
        title,
        companyInfo,
        writer,
        phone,
        email,
        content,
        date,
        status,
        password,
        reply
    ) {
        return new Board(
            id,
            title,
            companyInfo,
            writer,
            phone,
            email,
            content,
            date,
            status,
            password,
            reply
        );
    }
}

export default Board;
