import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDufQe2u1JZManWzSJ2g6iqVxD__NfuCrM",
    authDomain: "thinkline-92f3b.firebaseapp.com",
    projectId: "thinkline-92f3b",
    storageBucket: "thinkline-92f3b.appspot.com",
    messagingSenderId: "802438148745",
    appId: "1:802438148745:web:4d38a8d6d92313eeb0cc58",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const firestore = getFirestore(app);
export const storage = getStorage(app);
