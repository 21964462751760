import DepthNavbar from "components/Navbars/DepthNavbar";
import Board from "models/Board";
import React, { memo, useEffect, useState } from "react";
import { Instagram } from "react-content-loader";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { getBoardFromFirestore } from "services/boardService";
import BoardDetail from "./BoardDetail";

const QuestionDetail = memo(() => {
    const { id } = useParams();
    const [board, setBoard] = useState(Board.fromEmpty());
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");

        const fetchData = async () => {
            try {
                const boardData = await getBoardFromFirestore(id);
                setBoard(boardData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching product: ", error);
            }
        };
        fetchData();
    }, [id]);
    return (
        <>
            <DepthNavbar />
            <div className="shopDetailDivWrapper">
                <div style={{ height: "150px" }}></div>

                {loading ? <Instagram /> : <BoardDetail board={board} />}
                <Container
                    style={{
                        marginTop: "100px",
                    }}
                ></Container>
            </div>
        </>
    );
});

export default QuestionDetail;
