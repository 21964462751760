import { firestore } from "config/firebase";
import { collection, doc, getDoc, getDocs, deleteDoc } from "firebase/firestore";
import Board from "models/Board";
import { maskString } from "./customService";

export async function getBoardListFromFirestore() {
    const boardData = [];
    const querySnapshot = await getDocs(collection(firestore, "boards"));

    querySnapshot.forEach((doc) => {
        // Firestore 문서에서 데이터 추출
        const data = doc.data();

        // board 클래스로 변환
        const board = new Board(
            data.id,
            data.title,
            data.companyInfo,
            maskString(data.writer),
            data.phone,
            data.email,
            data.content,
            data.date,
            data.status,
            data.password,
            data.reply
        );

        boardData.push(board);
    });

    return boardData;
}

export async function getBoardListFromFirestoreAtAdmin() {
    const boardData = [];
    const querySnapshot = await getDocs(collection(firestore, "boards"));

    querySnapshot.forEach((doc) => {
        // Firestore 문서에서 데이터 추출
        const data = doc.data();

        // board 클래스로 변환
        const board = new Board(
            data.id,
            data.title,
            data.companyInfo,
            data.writer,
            data.phone,
            data.email,
            data.content,
            data.date,
            data.status,
            data.password,
            data.reply
        );

        boardData.push(board);
    });

    return boardData;
}

export async function getBoardFromFirestore(id) {
    const snapshot = await getDoc(doc(firestore, "boards", id));

    console.log(snapshot);

    if (snapshot.exists()) {
        const data = snapshot.data();

        return new Board(
            data.id,
            data.title,
            data.companyInfo,
            data.writer,
            data.phone,
            data.email,
            data.content,
            data.date,
            data.status,
            data.password,
            data.reply
        );
    } else {
        return null; // 문서가 존재하지 않을 때 처리
    }
}

export async function getBoardFromFirestoreAtAdmin(id) {
    const snapshot = await getDoc(doc(firestore, "boards", id));

    console.log(snapshot);

    if (snapshot.exists()) {
        const data = snapshot.data();

        return new Board(
            data.id,
            data.title,
            data.companyInfo,
            data.writer,
            data.phone,
            data.email,
            data.content,
            data.date,
            data.status,
            data.password,
            data.reply
        );
    } else {
        return null; // 문서가 존재하지 않을 때 처리
    }
}

export async function deleteBoardFromFirestore(id) {
    await deleteDoc(doc(firestore, "boards", id));
}