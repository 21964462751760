import React from "react";

import { NavbarBrand, Navbar, Container } from "reactstrap";

import favicon from "assets/img/favicon.png";

function AdminDepthNavbar2() {
    const [navbarColor] = React.useState("navbar-black");

    return (
        <>
            <Navbar
                className={"fixed-top " + navbarColor}
                expand="lg"
                color="info"
            >
                <Container>
                    <div className="navbar-translate">
                        <NavbarBrand
                            href="/admin/index"
                            id="navbar-brand"
                            style={{ fontSize: "1.5rem" }}
                        >
                            <h6>
                                <img src={favicon} alt="Logo" /> ThinkLine.AI
                            </h6>
                        </NavbarBrand>
                    </div>
                </Container>
            </Navbar>
        </>
    );
}

export default AdminDepthNavbar2;
