import DepthNavbar from "components/Navbars/DepthNavbar";
import React from "react";

import "./Consulting.css";
import { Button, Container } from "reactstrap";
import { Divider, Grid } from "@mui/material";

import ConsultingBlock2 from "components/ConsultingBlock/ConsultingBlock2";
import ConsultingBlock3 from "components/ConsultingBlock/ConsultingBlock3";
import ConsultingBlock4 from "components/ConsultingBlock/ConsultingBlock4";
import ConsultingBlock5 from "components/ConsultingBlock/ConsultingBlock5";

const ConsultingPage1 = () => {
    return (
        <>
            <DepthNavbar />
            <div style={{ height: "80px" }}></div>
            <ConsultingSectionPage />
        </>
    );

    function ConsultingSectionPage() {
        return (
            <>
                <div className="consulting-page-header">
                    <div
                        className="consulting-page-header-image"
                        style={{
                            backgroundImage:
                                "url(" +
                                require("assets/img/pictures/consulting1.jpg") +
                                ")",
                        }}
                    >
                        <Container>
                            <div className="consulting-content-center">
                                <h1>협업 운영</h1>
                                <br></br>
                                <h3>공통 보기를 통한 최고 성능 최적화</h3>
                                <br></br>

                                <h5>
                                    <Button
                                        outline
                                        style={{
                                            color: "white",
                                        }}
                                        onClick={() => {
                                            window.location.href =
                                                "/question/create";
                                        }}
                                    >
                                        고객 문의 및 상담
                                    </Button>
                                </h5>
                            </div>
                        </Container>
                    </div>
                </div>
                <div className="consulting-content-body">
                    <Grid container spacing={8}>
                        <Grid item xs={10} md={5}>
                            <div className="consulting-page-body-1">
                                <div className="consulting-page-body-title">
                                    <h1>
                                        지속 가능성과 수익성에 대한 공급망
                                        전체의 약속 구축
                                    </h1>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={10} md={4}>
                            <div className="consulting-page-body-2">
                                <div className="consulting-page-body-content">
                                    <b>THINKLINE</b> 협업 운영은 다양한 기능
                                    사용자 간의 단절을 제거하여 모든 관계자에게
                                    공통된 최신 보기를 제공하고 효율적인 팀
                                    협업을 보장하는 디지털 기능을 제공합니다.
                                    이를 통해 여러 부서 팀이 협업하고 혁신, 지속
                                    가능성, 문제를 해결할 수 있습니다. 팀
                                    구성원들이 공유된 비전에 참여함으로써 더
                                    많은 기여를 하고 행동과 책임에 대한 명확한
                                    보기를 제공할 수 있습니다.<br></br>
                                    <br></br>
                                    <b>THINKLINE</b> 협업 운영은 3DEXPERIENCE
                                    운영 비전의 초석입니다. 이러한 비전과 함께
                                    린 제조비즈니스라는 표준 영역이 등장하여 더
                                    나은 팀 관계를 구축하고 디지털 프레임워크
                                    내에서 <b>3D</b> 콘텐츠와 데이터를
                                    시각화합니다. 이 혁신적인 솔루션은 팀이
                                    협업을 촉진하고 문제를 해결하며 창의성을
                                    발휘할 수 있도록 도와줍니다.
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    <div style={{ height: "80px" }}></div>

                    <Grid container spacing={8}>
                        <Grid item xs={10} md={5}>
                            <div className="consulting-page-body-1">
                                <div className="consulting-page-body-title">
                                    <h1>팀 혁신 지원</h1>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={10} md={4}>
                            <div className="consulting-page-body-2">
                                <div className="consulting-page-body-content">
                                    <b>THINKLINE</b>은 모든 관계자가 공통된
                                    이해를 할 수 있도록 하면서 팀이 함께 혁신을
                                    이룰 수 있는 새로운 방법을 찾을 수 있도록
                                    지원함으로써 다양한 팀의 과제와 목표를
                                    해결하여 운영 효율성을 지속적으로
                                    개선합니다. <br></br>
                                    <br></br>효율적인 협업을 달성한 사용자를
                                    디지털 방식으로 연결하여 기업 전체에 대한
                                    가시성, 제어, 동기화를 제공합니다. 이 기능을
                                    통해 팀은 어디에서 어떻게 작업하든 발견,
                                    분석, 문제 해결을 안내하는 구조로 직원들을
                                    함께 모아서 새로운 혁신 방법을 찾을 수
                                    있습니다. 3DEXPERIENCE 플랫폼을 통해 린
                                    제조의 원칙에 따라 팀은 운영 낭비, 가변성,
                                    효율성을 해결할 수 있습니다.
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <Divider />
                <div className="consulting-page-section-title">
                    <h1> 함께 알아보기</h1>
                </div>
                <div className="consulting-page-section-div">
                    <div className="consulting-page-section">
                        <ConsultingBlock2 />
                        <ConsultingBlock3 />
                        <ConsultingBlock4 />
                        <ConsultingBlock5 />
                    </div>
                </div>
            </>
        );
    }
};

export default ConsultingPage1;
