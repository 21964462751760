import DepthNavbar from "components/Navbars/DepthNavbar";
import React from "react";

import "./Consulting.css";
import { Button, Container } from "reactstrap";
import { Divider } from "@mui/material";

import ConsultingBlock1 from "components/ConsultingBlock/ConsultingBlock1";
import ConsultingBlock2 from "components/ConsultingBlock/ConsultingBlock2";
import ConsultingBlock3 from "components/ConsultingBlock/ConsultingBlock3";
import ConsultingBlock4 from "components/ConsultingBlock/ConsultingBlock4";

const ConsultingPage5 = () => {
    return (
        <>
            <DepthNavbar />
            <div style={{ height: "80px" }}></div>
            <ConsultingSectionPage />
        </>
    );

    function ConsultingSectionPage() {
        return (
            <>
                <div className="consulting-page-header">
                    <div
                        className="consulting-page-header-image"
                        style={{
                            backgroundImage:
                                "url(" +
                                require("assets/img/pictures/consulting5.jpg") +
                                ")",
                        }}
                    >
                        <Container>
                            <div className="consulting-content-center">
                                <h1>공급망 계획 및 최적화</h1>
                                <br></br>
                                <h3>효율적인 공급망 계획</h3>
                                <br></br>

                                <h5>
                                    <Button
                                        outline
                                        style={{
                                            color: "white",
                                        }}
                                        onClick={() => {
                                            window.location.href =
                                                "/question/create";
                                        }}
                                    >
                                        고객 문의 및 상담
                                    </Button>
                                </h5>
                            </div>
                        </Container>
                    </div>
                </div>

                <div className="consulting-page-body">
                    <div className="consulting-page-body-1">
                        <div className="consulting-page-body-title">
                            <h1>인력 관리 최적화</h1>
                        </div>
                    </div>
                    <div className="consulting-page-body-2">
                        <div className="consulting-page-body-content">
                            <b>THINKLINE</b> 인력 스케줄링은 직원 일정과 계획
                            기능을 통해 인력 관리를 최적화합니다. 이것은 인력
                            관리 시스템에 대한 완벽한 가시성과 제어 기능을
                            제공하여, 계획과 일정 프로세스를 단일 인력 계획
                            소프트웨어로 통합합니다. 사용자는 근무조 선호 사항,
                            노동 규정과 같은 고유한 인력 일정 제약 사항을
                            고려하여 명단을 작성할 수 있습니다. 또한 직원들은
                            모바일 애플리케이션을 사용하여 교대 근무를
                            제시/교환하고, 휴가를 요청하고, 선호 근무 시간을
                            정의할 수 있습니다. 또한, 이 솔루션을 통해 사용자는
                            장기 인력 배치 계획이 수요를 충족시키고 작업과 작업
                            주문 할당을 최적화하여 활용도와 효율성을 극대화할 수
                            있는 방법을 이해할 수 있습니다.
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div className="consulting-page-body-1">
                        <div className="consulting-page-body-title">
                            <h1>
                                비즈니스 인텔리전스를 통한 진정한 통합 공급망
                                구축
                            </h1>
                        </div>
                    </div>
                    <div className="consulting-page-body-2">
                        <div className="consulting-page-body-content">
                            공급망 계획은 고객에게 제품을 제공하는 것과 관련된
                            복잡한 활동을 조율하는 데 중요한 역할을 합니다.
                            <b>THINKLINE SCPO</b>는 최첨단 기술과 산업 전문
                            지식을 활용하여 기업이 전체 공급망 네트워크를
                            최적화함으로써 민첩성을 개선하고 비용을 절감하며
                            서비스 수준을 향상시킬 수 있도록 지원합니다. 계획과
                            최적화 기술은 정보에 입각한 의사결정에 필수적인
                            통찰력을 제공하기 때문에 데이터에 크게 의존합니다.
                            이 때문에 데이터는 성공적인 공급망 계획의 핵심 동인
                            중 하나가 됩니다. 디지털 혁신이 확산됨에 따라 조직은
                            더 나은 선택을 위해 활용할 수 있는 풍부한 정보에
                            액세스할 수 있게 되었습니다. <b>THINKLINE</b>{" "}
                            솔루션은 데이터의 힘을 이용하여 실행 가능한 통찰력과
                            의사결정 지원을 제공함으로써 기업이 효율성과 성장을
                            촉진하는 전략적 선택을 할 수 있도록 지원합니다. 주요
                            관계자들은 전체 가치 네트워크를 통합하여 협업하고
                            고급 분석을 사용하여 공급망 가동 중단과 기타 예상치
                            못한 이벤트를 완화할 수 있습니다. 결과적으로, 혁신을
                            가속화하여 공급망 전반의 모든 활동에서 비즈니스
                            지속성과 복원력을 확보합니다. 공급망 최적화
                            소프트웨어는 기업이 경쟁력을 높이고 고객의 기대치를
                            지속적으로 충족시킬 수 있도록 지원합니다. 이를 통해
                            생산과 운송 비용을 줄이고 재고 관리를 개선하며 고객
                            만족도를 높여서 주문을 최적화할 수 있습니다. 당사의
                            소프트웨어는 고급 분석 및 머신러닝 알고리즘을 통해
                            기업이 패턴을 식별하고 수요를 예측하며 정확한 예측을
                            수행하는 동시에 예측하지 못한 이벤트에 대응할 수
                            있는 유연성을 유지할 수 있도록 지원합니다. 판매,
                            재고, 시장 동향 등 다양한 출처의 실시간 데이터를
                            통합함으로써, 고객의 필요를 예측하고 재고 관리를
                            최적화하여 공급업체가 적시 적소에 적절한 제품을
                            보유할 수 있도록 할 수 있습니다.
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div className="consulting-page-body-1">
                        <div className="consulting-page-body-title">
                            <h1>
                                <b>THINKLINE를</b> 통한 운영 최적화
                            </h1>
                        </div>
                    </div>
                    <div className="consulting-page-body-2">
                        <div className="consulting-page-body-content">
                            <b>THINKLINE를</b> 통한 운영 최적화 복잡성,
                            불확실성, 이질적인 프로세스가 증가함에 따라 공급망은
                            효율을 높여야 한다는 압박을 받고 있습니다. 기업은
                            공급망 프로세스의 효율과 비용 효율성을 개선함으로써
                            공급망 네트워크 최적화의 이점을 누릴 수 있습니다.
                            공급업체는 <b>THINKLINE SCPO</b>를 통해 조직 전체의
                            공급망 운영을 정확하게 모델링하고 통합함으로써
                            시장의 변동과 혼란에 자신 있게 대응할 수 있습니다.
                            하지만 <b>THINKLINE</b>의 공급망 네트워크 최적화
                            기능은 데이터 분석 이상의 기능을 제공합니다. 이
                            소프트웨어는 기업의 엔드 투 엔드 공급망을 평가하여
                            개선 기회를 파악하고 기업의 고유한 비즈니스 목표에
                            맞는 맞춤형 솔루션을 설계하는 종합적인 접근 방식을
                            취합니다. 운송 경로 최적화, 창고 배치 개선, 유통
                            채널 간소화 등 어떤 것이든, <b>THINKLINE SCPO</b>는
                            공급망의 모든 측면을 최적화하여 비용을 최소화하고
                            운영 효율성을 극대화합니다. 공급망, 엔지니어링,
                            제조의 관계자들을 디지털 방식으로 연결하면 신속한
                            운영 및 주문 관리가 가능한 혁신적인 변화를 이룰 수
                            있습니다. 공급업체는 전체적으로 자동화, 계획,
                            최적화를 이룰 수 있으므로, 더 나은 의사결정을 내리고
                            기업 목표를 달성할 수 있습니다.<br></br>
                            <br></br>
                            <b>THINKLINE</b>는 고객이 모든 공급망의 핵심이라는
                            것을 잘 알고 있습니다. 기업은 당사의 솔루션을
                            구현함으로써 적시 납품을 보장하고 리드 타임을 줄이며
                            주문 상태를 실시간으로 파악하여 고객 만족도를 높일
                            수 있습니다. 당사의 고객 중심 접근 방식은 기업들이
                            더욱 강력한 관계를 구축하고 브랜드 충성도를 높이며
                            시장에서 경쟁력을 확보할 수 있도록 지원합니다. 업계
                            최고의 전문성과 최첨단 기술을 갖춘 <b>THINKLINE</b>{" "}
                            솔루션은 고객과 파트너사들이 공급망의 잠재력을
                            최대한 활용하여 지속 가능한 성장과 수익성을 실현할
                            수 있도록 지원합니다.
                        </div>
                    </div>
                </div>
                <Divider />
                <div className="consulting-page-section-title">
                    <h1> 함께 알아보기</h1>
                </div>
                <div className="consulting-page-section-div">
                    <div className="consulting-page-section">
                        <ConsultingBlock1 />
                        <ConsultingBlock2 />
                        <ConsultingBlock3 />
                        <ConsultingBlock4 />
                    </div>
                </div>
            </>
        );
    }
};

export default ConsultingPage5;
