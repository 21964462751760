import React, { useState } from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Col,
} from "reactstrap";

// core components
import TransparentFooter from "components/Footers/TransparentFooter.js";
import { signInWithEmail } from "services/authService";

function SignIn() {
    const [firstFocus, setFirstFocus] = React.useState(false);
    const [lastFocus, setLastFocus] = React.useState(false);
    React.useEffect(() => {
        document.body.classList.add("login-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("login-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);

    const [email, setEmail] = useState(""); // 이메일 입력값 상태
    const [password, setPassword] = useState(""); // 패스워드 입력값 상태

    const handleEmailChange = (e) => {
        // 이메일 입력값이 변경될 때 호출되는 함수
        setEmail(e.target.value); // 이메일 상태 업데이트
    };

    const handlePasswordChange = (e) => {
        // 패스워드 입력값이 변경될 때 호출되는 함수
        setPassword(e.target.value); // 패스워드 상태 업데이트
    };

    const handleSubmit = async (e) => {
        // 폼 제출 시 실행될 함수
        e.preventDefault();

        await signInWithEmail(email, password); // 이메일/패스워드로 로그인
    };
    return (
        <>
            <div className="page-header clear-filter" filter-color="blue">
                <div
                    className="page-header-image"
                    style={{
                        backgroundImage:
                            "url(" +
                            require("assets/img/factory-header.jpg") +
                            ")",
                    }}
                ></div>
                <div className="content">
                    <Container>
                        <Col className="ml-auto mr-auto" md="4">
                            <Card className="card-login card-plain">
                                <Form action="" className="form" method="">
                                    <CardHeader className="text-center">
                                        <h3>Thinkline AI</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <InputGroup
                                            className={
                                                "no-border input-lg" +
                                                (firstFocus
                                                    ? " input-group-focus"
                                                    : "")
                                            }
                                        >
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="now-ui-icons users_circle-08"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="이메일"
                                                type="text"
                                                value={email}
                                                onChange={handleEmailChange}
                                                onFocus={() =>
                                                    setFirstFocus(true)
                                                }
                                                onBlur={() =>
                                                    setFirstFocus(false)
                                                }
                                            ></Input>
                                        </InputGroup>
                                        <InputGroup
                                            className={
                                                "no-border input-lg" +
                                                (lastFocus
                                                    ? " input-group-focus"
                                                    : "")
                                            }
                                        >
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="now-ui-icons objects_key-25"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="비밀번호"
                                                type="password"
                                                value={password}
                                                onChange={handlePasswordChange}
                                                onFocus={() =>
                                                    setLastFocus(true)
                                                }
                                                onBlur={() =>
                                                    setLastFocus(false)
                                                }
                                            ></Input>
                                        </InputGroup>
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <Button
                                            block
                                            className="btn-round"
                                            color="info"
                                            onClick={handleSubmit}
                                            size="lg"
                                            style={{ background: "black" }}
                                        >
                                            로그인
                                        </Button>
                                        <div className="pull-left">
                                            <h6>
                                                <a
                                                    className="link"
                                                    href="#pablo"
                                                    onClick={(e) =>
                                                        e.preventDefault()
                                                    }
                                                >
                                                    회원가입
                                                </a>
                                            </h6>
                                        </div>
                                    </CardFooter>
                                </Form>
                            </Card>
                        </Col>
                    </Container>
                </div>
                <TransparentFooter />
            </div>
        </>
    );
}

export default SignIn;
