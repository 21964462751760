import React from "react";

import "./ConsultingBlock.css";

import image from "assets/img/pictures/consulting2.jpg";

const ConsultingBlock2 = () => {
    return (
        <div className="consultingBlock" onClick={() => {
            window.location.href = "/consulting/2";
        }}>
            <div className="consultingBlockImage">
                <img src={image} alt="" />
            </div>
            <div className="consultingBlockText">
                <h3>산업 엔지니어링</h3>
                <p>글로벌 운영 계획, 시뮬레이션, 모델링</p>
            </div>
        </div>
    );
};

export default ConsultingBlock2;
