import DepthNavbar from "components/Navbars/DepthNavbar";
import React from "react";

import "./Consulting.css";
import { Button, Container } from "reactstrap";
import { Divider, Grid } from "@mui/material";

import ConsultingBlock1 from "components/ConsultingBlock/ConsultingBlock1";
import ConsultingBlock2 from "components/ConsultingBlock/ConsultingBlock2";
import ConsultingBlock4 from "components/ConsultingBlock/ConsultingBlock4";
import ConsultingBlock5 from "components/ConsultingBlock/ConsultingBlock5";

const ConsultingPage3 = () => {
    return (
        <>
            <DepthNavbar />
            <div style={{ height: "80px" }}></div>
            <ConsultingSectionPage />
        </>
    );

    function ConsultingSectionPage() {
        return (
            <>
                <div className="consulting-page-header">
                    <div
                        className="consulting-page-header-image"
                        style={{
                            backgroundImage:
                                "url(" +
                                require("assets/img/pictures/consulting3.jpg") +
                                ")",
                        }}
                    >
                        <Container>
                            <div className="consulting-content-center">
                                <h1>제조 및 운영</h1>
                                <br></br>
                                <h3>운영 효율성 달성</h3>
                                <br></br>

                                <h5>
                                    <Button
                                        outline
                                        style={{
                                            color: "white",
                                        }}
                                        onClick={() => {
                                            window.location.href =
                                                "/question/create";
                                        }}
                                    >
                                        고객 문의 및 상담
                                    </Button>
                                </h5>
                            </div>
                        </Container>
                    </div>
                </div>
                <div className="consulting-content-body">
                    <Grid container spacing={8}>
                        <Grid item xs={10} md={5}>
                            <div className="consulting-page-body-1">
                                <div className="consulting-page-body-title">
                                    <h1>
                                        제조와 운영의 가상 세계와 실제 세계 연결
                                    </h1>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={10} md={4}>
                            <div className="consulting-page-body-2">
                                <div className="consulting-page-body-content">
                                    <b>THINKLINE</b> <b>제조 및 운영</b>{" "}
                                    솔루션은 운영 효율성을 높이고 유지하기 위해
                                    글로벌 운영을 혁신합니다. 공유 디지털 환경이
                                    글로벌 규모의 제조 운영 및 공급망
                                    프로세스에서 가시성, 통제력, 동기화를
                                    개선하기 위해 모든 관계자들을 연결합니다.
                                    또한 실시간 제조 인텔리전스를 제공하여,
                                    자동화된 생산 및 품질 관리를 통해 글로벌
                                    지속적 개선을 지원하고 낭비 영역을 식별하여
                                    지속 가능성을 개선합니다. 제조업체들은 이
                                    공유 디지털 환경을 통해 구현되는 가상 세계와
                                    실제 세계를 연결하여 이를 달성할 수
                                    있습니다.<br></br>
                                    <br></br>
                                    <b>THINKLINE</b> <b>제조 및 운영</b>{" "}
                                    솔루션은 제조 실행 <b>시스템(MES)</b>을 통해
                                    최적화된 결과를 얻을 수 있는 이점을
                                    제공하여, 원자재에서 완제품으로 전환을
                                    추적하고 문서화하여 운영 효율성을
                                    유지합니다. 이러한 MES 기능은 사용자가
                                    대화형 3D, 컴퓨터 비전, AI, 딥 러닝 등의
                                    기술을 활용하여 복잡한 조립 작업에서 인간
                                    작업자를 안내하고 제품 버추얼 트윈을
                                    기반으로 품질 검사를 자동화하는 당사의 증강
                                    현실 기능을 통해 더욱 향상됩니다.
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    <div style={{ height: "80px" }}></div>

                    <Grid container spacing={8}>
                        <Grid item xs={10} md={5}>
                            <div className="consulting-page-body-1">
                                <div className="consulting-page-body-title">
                                    <h1>
                                        신제품, 시설, 생산 프로세스 계획에 대한
                                        통찰력 확보
                                    </h1>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={10} md={4}>
                            <div className="consulting-page-body-2">
                                <div className="consulting-page-body-content">
                                    또한 <b>THINKLINE</b>는 모델 기반의 데이터
                                    중심 디지털 경험을 기반으로 하는 더욱
                                    광범위한 솔루션을 제공합니다. 좀더 포괄적인
                                    솔루션으로 전환하면 플랜트 현장과, 생산
                                    프로세스와 제품 공급 네트워크 전반의 창고,
                                    품질, 자재 흐름을 포괄하는 제조 혁신을 이룰
                                    수 있습니다. 이것은{" "}
                                    <b>제조 운영 관리(MOM)</b>로 정의되며, 이를
                                    통해 제조 우수성을 달성하고 유지하는 데
                                    필요한 제어와 통찰력을 얻을 수 있습니다. 그
                                    결과, 기업 및 확장된 가치 네트워크에서
                                    민첩성이 향상되고 지속적인 개선이
                                    확대됩니다.
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <Divider />
                <div className="consulting-page-section-title">
                    <h1> 함께 알아보기</h1>
                </div>
                <div className="consulting-page-section-div">
                    <div className="consulting-page-section">
                        <ConsultingBlock1 />
                        <ConsultingBlock2 />
                        <ConsultingBlock4 />
                        <ConsultingBlock5 />
                    </div>
                </div>
            </>
        );
    }
};

export default ConsultingPage3;
