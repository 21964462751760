import emailjs from "emailjs-com";

export function sendMail(to_email, from_name, message) {
    const templateParams = {
        from_name: from_name,
        message: message,
    };

    emailjs
        .send(
            "service_gsedr5b", // 서비스 ID
            "template_wzydi9c", // 템플릿 ID
            templateParams,
            "nJxxiZkrae2Fg_w4m" // public-key
        )
        .then((response) => {
            console.log("이메일이 성공적으로 보내졌습니다:", response);
        })
        .catch((error) => {
            console.error("이메일 보내기 실패:", error);
        });
}

export function sendAnswerMail(to_email, to_name, from_name, answer,message) {
    const templateParams = {
        to_email: to_email,
        to_name: to_name,
        from_name: from_name,
        answer: answer,
        message: message,
    };

    emailjs
        .send(
            "service_gsedr5b", // 서비스 ID
            "template_44mwsdg", // 템플릿 ID
            templateParams,
            "nJxxiZkrae2Fg_w4m" // public-key
        )
        .then((response) => {
            console.log("이메일이 성공적으로 보내졌습니다:", response);
        })
        .catch((error) => {
            console.error("이메일 보내기 실패:", error);
        });
}
