import React from "react";

import "./ConsultingBlock.css";

import image from "assets/img/pictures/consulting5.jpg";

const ConsultingBlock5 = () => {
    return (
        <div
            className="consultingBlock"
            onClick={() => {
                window.location.href = "/consulting/5";
            }}
        >
            <div className="consultingBlockImage">
                <img src={image} alt="" />
            </div>
            <div className="consultingBlockText">
                <h3>공급망 계획 및 최적화</h3>
                <p>효율적인 공급망 계획</p>
            </div>
        </div>
    );
};

export default ConsultingBlock5;
