/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { LineRoundedArrowRight3 } from "../../icons/LineRoundedArrowRight3";
import { LineRoundedArrowRight8 } from "../../icons/LineRoundedArrowRight8";
import { LineRoundedSearch2 } from "../../icons/LineRoundedSearch2";
import { LineRoundedSearch4 } from "../../icons/LineRoundedSearch4";
import { LineRoundedSearch7 } from "../../icons/LineRoundedSearch7";
import "./style.css";

export const MasterSecondary = ({
    iconLeft = true,
    iconRight = true,
    size,
    type,
    className,
    buttonTextClassName,
    text = "Default",
    onClick,
}) => {
    return (
        <div
            className={`master-secondary ${size} ${type} ${className}`}
            onClick={onClick}
        >
            {iconLeft && (
                <>
                    <>
                        {["default", "small"].includes(size) && (
                            <LineRoundedSearch2
                                className="line-rounded-search"
                                color={
                                    type === "secondary" ? "white" : "#5D5A88"
                                }
                            />
                        )}

                        {size === "smaller" && (
                            <LineRoundedSearch7
                                className={`${
                                    type === "secondary" ? "class" : "class-2"
                                }`}
                                color={
                                    type === "secondary" ? "white" : "#5D5A88"
                                }
                            />
                        )}

                        {size === "large" && (
                            <LineRoundedSearch4
                                className="instance-node"
                                color={
                                    type === "secondary" ? "white" : "#5D5A88"
                                }
                            />
                        )}
                    </>
                </>
            )}

            <div className={`button-text ${buttonTextClassName}`}>{text}</div>
            {iconRight && (
                <>
                    <>
                        {["default", "small", "smaller"].includes(size) && (
                            <LineRoundedArrowRight8
                                className={`${
                                    type === "primary" && size === "smaller"
                                        ? "class-3"
                                        : type === "secondary" &&
                                          size === "smaller"
                                        ? "class-4"
                                        : "line-rounded-search"
                                }`}
                                color={
                                    type === "secondary" ? "white" : "#5D5A88"
                                }
                            />
                        )}

                        {size === "large" && (
                            <LineRoundedArrowRight3
                                className="instance-node"
                                color={
                                    type === "secondary" ? "white" : "#5D5A88"
                                }
                            />
                        )}
                    </>
                </>
            )}
        </div>
    );
};

MasterSecondary.propTypes = {
    iconLeft: PropTypes.bool,
    iconRight: PropTypes.bool,
    size: PropTypes.oneOf(["default", "smaller", "large", "small"]),
    type: PropTypes.oneOf(["primary", "secondary"]),
    text: PropTypes.string,
};
