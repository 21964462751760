import DepthNavbar from "components/Navbars/DepthNavbar";
import React, { useEffect } from "react";
import BannerHeader from "views/index-sections/BannerHeader";
import QuestionCreateForm from "./QuestionCreateForm";

const CreateQuestion = () => {
    useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");

        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            <DepthNavbar />
            <BannerHeader title="Q&A" description="문의 사항을 남겨주세요." />
            <QuestionCreateForm />
        </>
    );
};

export default CreateQuestion;
