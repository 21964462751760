export function limitText(text, maxLength) {
    if (text.length <= maxLength) {
        return text;
    }
    return text.slice(0, maxLength) + "...";
}

export function priceToWon(price) {
    if (price !== undefined && price !== null) {
        if (isNaN(price)) {
            return price;
        } else {
            const priceString = price.toString();
            return priceString.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원";
        }
    }
    // 가격이 정의되지 않았거나 null인 경우, 기본값 반환 또는 오류 처리
    return "가격 없음";
}

export const ProductTypes = [
    { value: "1", label: "Design solutions" },
    { value: "2", label: "Project management solutions" },
    { value: "3", label: "Rendering solutions" },
];

export const QuestionProductTypes = [
    { value: "1", label: "협업 운영" },
    { value: "2", label: "산업 엔지니어링" },
    { value: "3", label: "물류 및 인력" },
    { value: "4", label: "제조 및 운영" },
    { value: "5", label: "공급망 계획 및 최적화" },
    { value: "6", label: "모니터링 솔루션" },
    { value: "7", label: "생산관리" },
    { value: "8", label: "MES" },
    { value: "9", label: "SCM" },
    { value: "10", label: "자동화 엔지니어링" },
    { value: "11", label: "기타" },
];

export const QuestionTypes = [
    { value: "1", label: "견적 요청" },
    { value: "2", label: "데모 요청" },
    { value: "3", label: "Consulting" },
];

export function getOption(value) {
    for (let i = 0; i < ProductTypes.length; i++) {
        if (ProductTypes[i].value === value) {
            return ProductTypes[i];
        }
    }
    return ProductTypes[0];
}

export function getLabel(value) {
    for (let i = 0; i < ProductTypes.length; i++) {
        if (ProductTypes[i].value === value) {
            return ProductTypes[i].label;
        }
    }
    return "Unknown";
}

export function maskString(inputString) {
    const prefix = inputString.substring(0, 2);
    const maskedPart = "*".repeat(inputString.length - 2);

    return prefix + maskedPart;
}
