import { useRef, useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
import { storage } from "config/firebase";
import { v4 as uuidv4 } from "uuid";

import "./ShopCreateForm.css";
import defaultImage from "assets/img/default-product.jpg";
import { firestore } from "config/firebase";
import Product from "models/Product";
import Loading from "components/Loading/Loading";
import ReactSelect from "react-select";
import { ProductTypes } from "services/customService";

const ShopCreateForm = () => {
    const selectFile = useRef("");

    const [isLoading, setIsLoading] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");

    const [selectType, setSelectType] = useState(ProductTypes[0]);

    const handleTitleChange = (event) => {
        setTitle(event.target.value); // textarea의 내용을 상태에 업데이트
    };
    const handleDescriptionChange = (event) => {
        setDescription(event.target.value); // textarea의 내용을 상태에 업데이트
    };
    const handlePriceChange = (event) => {
        setPrice(event.target.value); // textarea의 내용을 상태에 업데이트
    };

    const onUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            setImageSrc(reader.result || null); // 파일의 컨텐츠
        };
    };

    const updateToFirebase = async () => {
        setIsLoading(true);
        var url;
        // 값이 다 있는지 비교
        if (title === "") {
            alert("제품 이름을 입력하세요.");
            setIsLoading(false);
            return;
        }
        if (description === "") {
            alert("제품 설명을 입력하세요.");
            setIsLoading(false);
            return;
        }
        if (price === "") {
            alert("제품 가격을 입력하세요.");
            setIsLoading(false);
            return;
        }
        if (!imageSrc) {
            alert("이미지를 선택하세요.");
            setIsLoading(false);
            return;
        }
        const productId = uuidv4();
        try {
            const storageRef = ref(storage, "products/" + productId);
            await uploadBytes(storageRef, selectFile.current.files[0]);
            url = await getDownloadURL(storageRef);
        } catch (e) {
            console.log(e);
            alert("이미지 업로드 중 에러가 발생했습니다.");
            alert(e.message);
            setIsLoading(false);
            return;
        }

        // Firestore 'product' collection에 저장
        const db = firestore;

        const productData = Product.fromSet(
            productId,
            title,
            price,
            description,
            selectType.value,
            url
        );

        const docRef = doc(db, "products", productData.id);

        try {
            await setDoc(docRef, productData.toFirestore());
            setImageSrc(null);
            setTitle("");
            setDescription("");
            setPrice("");
            setIsLoading(false);
            alert("제품이 추가되었습니다.");
        } catch (e) {
            setIsLoading(false);
            alert(e.message);
            alert("데이터 생성 중 에러가 발생했습니다.");
        }
    };
    return (
        <>
            {isLoading && (
                <div className="modal">
                    <Loading />
                </div>
            )}

            <div className={`createFormBody ${isLoading ? "blur" : ""}`}>
                <div className="shop-create-form">
                    <img
                        className="image-preview"
                        alt=""
                        src={imageSrc ? imageSrc : defaultImage}
                    />
                    <div className="input-section">
                        <div className="parent">
                            <b className="b">제품군</b>
                            <ReactSelect
                                className="frame-select"
                                options={ProductTypes}
                                onChange={setSelectType}
                                defaultValue={ProductTypes[0]}
                            />
                        </div>
                        <div className="parent">
                            <b className="b">제품 이름</b>
                            <p>
                                <textarea
                                    className="frame-child"
                                    placeholder="제품 이름을 입력하세요"
                                    type="text"
                                    value={title}
                                    onChange={handleTitleChange}
                                />
                            </p>
                        </div>
                        <div className="parent">
                            <b className="b">제품 설명</b>
                            <p>
                                <textarea
                                    placeholder="제품 설명을 입력하세요."
                                    className="frame-item"
                                    type="text"
                                    value={description}
                                    onChange={handleDescriptionChange}
                                />
                            </p>
                        </div>
                        <div className="parent">
                            <b className="b">제품 가격</b>
                            <p>
                                <textarea
                                    className="frame-child"
                                    type="text"
                                    placeholder="가격을 입력하세요."
                                    value={price}
                                    onChange={handlePriceChange}
                                />
                            </p>
                        </div>
                    </div>
                    <button
                        className="save-button"
                        onClick={async () => {
                            await updateToFirebase();
                        }}
                    >
                        <b className="save-button-text">저장</b>
                    </button>
                    <button
                        className="file-search-button"
                        onClick={() => {
                            selectFile.current.click();
                        }}
                    >
                        <b className="file-search-button-text">파일찾기</b>
                    </button>
                    <input
                        accept="image/*"
                        multiple
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => {
                            try {
                                onUpload(e);
                            } catch (e) {
                                setImageSrc(null);
                            }
                        }}
                        ref={selectFile}
                    />
                </div>
            </div>
        </>
    );
};

export default ShopCreateForm;
