import DepthNavbar from "components/Navbars/DepthNavbar";

import BannerHeader from "../index-sections/BannerHeader.js";
import ShopList from "views/shop/ShopList.js";

const Shop = () => {
    return (
        <>
            <DepthNavbar />
            <BannerHeader
                title="Product"
                description="자사에서 공급하는 제품입니다."
            />
            <ShopList />
        </>
    );
};

export default Shop;
