import React, { useEffect } from "react";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import About from "./index-sections/About";
import { useParams } from "react-router-dom";
import ConsultingSection from "./index-sections/ConsultingSection";

function Index() {
    const { id } = useParams();

    useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");

        const section = document.getElementById(id);
        if (section) {
            const scroll = () => {
                section.scrollIntoView({ behavior: "smooth" });
            };
            requestAnimationFrame(scroll);
        }

        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, [id]);
    return (
        <>
            <IndexNavbar />
            <div className="wrapper">
                <IndexHeader />
                <About />
                <ConsultingSection />
                <div className="main"></div>
            </div>
        </>
    );
}

export default Index;
