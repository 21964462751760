import React from "react";

import "./ConsultingBlock.css";

import image from "assets/img/pictures/consulting4.jpg";

const ConsultingBlock4 = () => {
    return (
        <div className="consultingBlock" onClick={() => {
            window.location.href = "/consulting/4";
        }}>
            <div className="consultingBlockImage">
                <img src={image} alt="" />
            </div>
            <div className="consultingBlockText">
                <h3>물류 및 인력</h3>
                <p>THINKLINE을 통한 물류 최적화 및 인력 관리</p>
            </div>
        </div>
    );
};

export default ConsultingBlock4;
