import React from "react";

import "./ConsultingBlock.css";

import image from "assets/img/pictures/consulting3.jpg";

const ConsultingBlock3 = () => {
    return (
        <div className="consultingBlock" onClick={() => {
            window.location.href = "/consulting/3";
        }}>
            <div className="consultingBlockImage">
                <img src={image} alt="" />
            </div>
            <div className="consultingBlockText">
                <h3>제조 및 운영</h3>
                <p>운영 효율성 달성</p>
            </div>
        </div>
    );
};

export default ConsultingBlock3;
