import React, { memo, useEffect, useState } from "react";

import DepthNavbar from "components/Navbars/DepthNavbar";
import BannerHeader from "views/index-sections/BannerHeader";

import "./Question.css";
import { Button, Table } from "reactstrap";
import { TableBody, TableHead } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { getBoardListFromFirestore } from "services/boardService";
import { List } from "react-content-loader";

const Question = memo(() => {
    const [boards, setBoards] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");

        const fetchProducts = async () => {
            try {
                const boardData = await getBoardListFromFirestore();
                setBoards(boardData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching products: ", error);
            }
        };

        fetchProducts();

        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);

    return (
        <>
            <DepthNavbar />
            <BannerHeader title="Q&A" description="문의 사항을 남겨주세요." />
            <div className="question-div">
                <Button
                    className="question-button"
                    onClick={() => {
                        window.location.href = "/question/create";
                    }}
                >
                    질문하기
                </Button>
                {loading ? (
                    <List />
                ) : (
                    <Table striped bordered hover className="board-table">
                        <TableHead>
                            <tr>
                                <th>No.</th>
                                <th>Title.</th>
                                <th>Writer.</th>
                                <th>Date.</th>
                                <th>Status.</th>
                            </tr>
                        </TableHead>
                        {tableBody()}
                    </Table>
                )}
            </div>
        </>
    );

    function tableBody() {
        return (
            <TableBody>
                {boards.length === 0 ? (
                    <td colSpan="5">게시글이 없습니다.</td>
                ) : (
                    boards.map((board, index) => {
                        return (
                            <React.Fragment key={index}>
                                <tr
                                    className="board-tr"
                                    onClick={() => {
                                        window.location.href = `/question/index/${board.id}`;
                                    }}
                                >
                                    <td>{index + 1}</td>
                                    <td>
                                        <LockIcon />
                                        {board.title}
                                    </td>
                                    <td>{board.writer}</td>
                                    <td>{board.date}</td>
                                    <td>{board.status}</td>
                                </tr>
                            </React.Fragment>
                        );
                    })
                )}
            </TableBody>
        );
    }
});

export default Question;
