import { useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

import "./CreateQuestion.css";
import { firestore } from "config/firebase";
import Loading from "components/Loading/Loading";
import ReactSelect from "react-select";
import { QuestionTypes } from "services/customService";
import Board from "models/Board";
import { QuestionProductTypes } from "services/customService";
import { sendMail } from "services/emailService";

const QuestionCreateForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [writer, setWriter] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [content, setContent] = useState("");
    const [password, setPassword] = useState("");
    const [companyInfo, setCompanyInfo] = useState("");

    const [selectQuestionType, setQuestionSelectType] = useState(
        QuestionTypes[0]
    );
    const [selectQuestionProductType, setSelectQuestionProductType] = useState(
        QuestionProductTypes[0]
    );

    const handleCompanyInfoChange = (event) => {
        setCompanyInfo(event.target.value);
    };
    const handleWriterChange = (event) => {
        setWriter(event.target.value);
    };
    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handleContentChange = (event) => {
        setContent(event.target.value);
    };
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const updateToFirebase = async () => {
        setIsLoading(true);

        if (writer === "") {
            alert("작성자 성함을 입력하세요.");
            setIsLoading(false);
            return;
        }
        if (companyInfo === "") {
            alert("기업정보(업체면/매출액/사업군/제품군)를 입력하세요.");
            setIsLoading(false);
            return;
        }
        if (writer.length < 3) {
            alert("작성자 성함은 3자리 이상으로 입력하세요.");
            setIsLoading(false);
            return;
        }
        if (phone === "") {
            alert("연락처를 입력하세요.");
            setIsLoading(false);
            return;
        }
        if (email === "") {
            alert("이메일을 입력하세요.");
            setIsLoading(false);
            return;
        }
        if (password === "") {
            alert("비밀번호를 입력하세요.");
            setIsLoading(false);
            return;
        }
        if (password.length < 4) {
            alert("비밀번호는 4자리 이상으로 입력하세요.");
            setIsLoading(false);
            return;
        }
        const boardId = uuidv4();

        // Firestore 'product' collection에 저장
        const db = firestore;
        const formattedDate = dateFormatString();

        const boardData = Board.fromSet(
            boardId,
            `[${selectQuestionType.label}] ${selectQuestionProductType.label}`,
            companyInfo,
            writer,
            phone,
            email,
            content,
            formattedDate,
            "답변 미처리",
            password,
            ""
        );

        const docRef = doc(db, "boards", boardData.id);

        sendMail(
            writer,
            `문의유형:  [${selectQuestionType.label}]\n\n문의제품:  ${selectQuestionProductType.label}\n\n문의사항(상세):  ${content}\n\n연락처:  ${phone}\n\n기업정보: ${companyInfo}\n\nEmail:  ${email}`
        );

        try {
            await setDoc(docRef, boardData.toFirestore());
            window.history.back();
            alert("문의사항이 등록되었습니다.");
        } catch (e) {
            setIsLoading(false);
            alert(e.message);
            alert("데이터 생성 중 에러가 발생했습니다.");
        }
    };
    return (
        <>
            {isLoading && (
                <div className="question-modal">
                    <Loading />
                </div>
            )}

            <div
                className={`question-createFormBody ${isLoading ? "blur" : ""}`}
            >
                <div className="question-create-form">
                    <div className="question-input-section">
                        <div className="question-parent">
                            <b className="question-b">문의제품</b>
                            <ReactSelect
                                className="question-frame-select"
                                options={QuestionProductTypes}
                                onChange={setSelectQuestionProductType}
                                defaultValue={selectQuestionProductType}
                            />
                        </div>
                        <div className="question-parent">
                            <b className="question-b">문의유형</b>
                            <ReactSelect
                                className="question-frame-select"
                                options={QuestionTypes}
                                onChange={setQuestionSelectType}
                                defaultValue={selectQuestionType}
                            />
                        </div>
                        <div className="question-parent">
                            <b className="question-b">기업정보</b>
                            <p>
                                <textarea
                                    className="question-frame-item"
                                    type="text"
                                    placeholder="업체명/매출액/사업군/제품군 정보를 포함하여 입력하세요."
                                    value={companyInfo}
                                    onChange={handleCompanyInfoChange}
                                />
                            </p>
                        </div>
                        <div className="question-parent">
                            <b className="question-b">
                                문의사항 상세(선택사항)
                            </b>
                            <p>
                                <textarea
                                    placeholder="상세한 문의 내용을 입력하세요."
                                    className="question-frame-item"
                                    type="text"
                                    value={content}
                                    onChange={handleContentChange}
                                />
                            </p>
                        </div>
                        <div className="question-parent">
                            <b className="question-b">담당자 성함</b>
                            <p>
                                <textarea
                                    className="question-frame-child"
                                    type="text"
                                    placeholder="담당자 성함을 입력하세요."
                                    value={writer}
                                    onChange={handleWriterChange}
                                />
                            </p>
                        </div>
                        <div className="question-parent">
                            <b className="question-b">연락처</b>
                            <p>
                                <textarea
                                    className="question-frame-child"
                                    type="text"
                                    placeholder="연락처를 입력하세요."
                                    value={phone}
                                    onChange={handlePhoneChange}
                                />
                            </p>
                        </div>
                        <div className="question-parent">
                            <b className="question-b">이메일</b>
                            <p>
                                <textarea
                                    className="question-frame-child"
                                    type="text"
                                    placeholder="이메일을 입력하세요."
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                            </p>
                        </div>
                        <div className="question-parent">
                            <b className="question-b">비밀번호</b>
                            <p>
                                <input
                                    className="question-frame-child"
                                    type="password"
                                    placeholder="비밀번호(4자리 이상)을 입력하세요."
                                    value={password}
                                    onChange={handlePasswordChange}
                                />
                            </p>
                        </div>
                    </div>
                    <button
                        className="question-save-button"
                        onClick={async () => {
                            await updateToFirebase();
                        }}
                    >
                        <b className="question-save-button-text">저장</b>
                    </button>
                </div>
            </div>
        </>
    );

    function dateFormatString() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 +1을 하고 2자리 숫자로 포맷합니다.
        const day = String(currentDate.getDate()).padStart(2, "0"); // 일자를 2자리 숫자로 포맷합니다.
        const hours = String(currentDate.getHours()).padStart(2, "0"); // 시간을 2자리 숫자로 포맷합니다.
        const minutes = String(currentDate.getMinutes()).padStart(2, "0"); // 분을 2자리 숫자로 포맷합니다.

        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
        return formattedDate;
    }
};

export default QuestionCreateForm;
