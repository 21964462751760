import React, { useState } from "react";
import { MasterSecondary } from "components/MasterSecondary";
import { priceToWon } from "services/customService";

import ProtoType from "prop-types";
import Product from "models/Product";
import "./FrameStyle.css";

import divider from "assets/img/divider.png";
import { getLabel } from "services/customService";

const Frame = ({ product }) => {
    // const [count, setCount] = React.useState(1);

    const [responsive, setResponsive] = useState("");

    React.useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 1166) {
                setResponsive("responsive");
            } else {
                setResponsive("");
            }
        }

        // 초기 로드 시와 화면 크기 변경 시에도 체크
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };

        // Initial call to set the column size
    }, []);

    const description = (
        <div className="detail-div-3">
            <p className="detail-p">{product.description}</p>
            <div className="detail-div-4">
                <div className="detail-text-wrapper-4">판매가</div>
                <div className="detail-text-wrapper-5">
                    {priceToWon(product.price)}
                </div>
            </div>
            <div className={`detail-div-5 ${responsive}`}>
                <MasterSecondary
                    buttonTextClassName="detail-master-secondary-instance"
                    className="detail-master-secondary-button"
                    iconLeft={false}
                    iconRight={false}
                    size="small"
                    text="문의하기"
                    type="primary"
                    onClick={() => {
                        window.location.href = "/question/create";
                    }}
                />
            </div>
        </div>
    );
    const productImage = (
        <img className="detail-frame-image" src={product.imageUrl} alt="" />
    );
    return (
        <div className="detail-frame">
            <div className="detail-div">
                <div className="detail-details">
                    <div className="detail-div-wrapper">
                        <div className="detail-text-wrapper">
                            {getLabel(product.type)}
                        </div>
                    </div>
                    <div className="detail-text-wrapper-2">{product.name}</div>
                </div>
                <img className="detail-vector" alt="Vector" src={divider} />
            </div>
            {responsive === "responsive" ? (
                <>
                    {productImage}
                    {description}
                </>
            ) : (
                <div className="detail-div-2">
                    {productImage}
                    {description}
                </div>
            )}
        </div>
    );
};

Frame.protoTypes = {
    product: ProtoType.instanceOf(Product).isRequired,
};

export default Frame;
