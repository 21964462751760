import React, { memo } from "react";
import { useParams } from "react-router-dom";
import ConsultingPage1 from "./ConsultingPage1";
import ConsultingPage2 from "./ConsultingPage2";
import ConsultingPage3 from "./ConsultingPage3";
import ConsultingPage4 from "./ConsultingPage4";
import ConsultingPage5 from "./ConsultingPage5";

const Consulting = memo(() => {
    const { id } = useParams();
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");

        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    let consultingPage;
    switch (id) {
        case "1":
            consultingPage = <ConsultingPage1 />;
            break;
        case "2":
            consultingPage = <ConsultingPage2 />;
            break;
        case "3":
            consultingPage = <ConsultingPage3 />;
            break;
        case "4":
            consultingPage = <ConsultingPage4 />;
            break;
        case "5":
            consultingPage = <ConsultingPage5 />;
            break;

        default:
            consultingPage = <ConsultingPage1 />;
    }

    return <div>{consultingPage}</div>;
});

export default Consulting;
