import React from "react";
import { SyncLoader } from "react-spinners";

import "./Loading.css";

const Loading = () => {
    return (
        <div className="spinner">
            <SyncLoader color="white"/>
        </div>
    );
};

export default Loading;
