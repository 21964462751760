import React from "react";

import {
    Button,
    Collapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
} from "reactstrap";

import favicon from "assets/img/favicon.png";
import { logout } from "services/authService";

function AdminIndexNavbar({ email }) {
    const [navbarColor] = React.useState("navbar-black");
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    const [resize, setResize] = React.useState();

    React.useEffect(() => {
        window.addEventListener("resize", setResize(window.innerWidth));
        return () =>
            window.removeEventListener("resize", setResize(window.innerWidth));
    }, []);

    return (
        <>
            {collapseOpen ? (
                <div
                    id="bodyClick"
                    onClick={() => {
                        toggleCollapse(setCollapseOpen, false);
                    }}
                />
            ) : null}
            <Navbar
                className={"fixed-top " + navbarColor}
                expand="lg"
                color="info"
            >
                <Container>
                    <div className="navbar-translate">
                        <NavbarBrand
                            href="/admin/index"
                            id="navbar-brand"
                            style={{ fontSize: "1.5rem" }}
                        >
                            <h6>
                                <img src={favicon} alt="Logo" /> ThinkLine.AI
                            </h6>
                        </NavbarBrand>
                        <button
                            className="navbar-toggler navbar-toggler"
                            onClick={() => {
                                toggleCollapse(setCollapseOpen, !collapseOpen);
                            }}
                            aria-expanded={collapseOpen}
                            type="button"
                        >
                            <span className="navbar-toggler-bar top-bar"></span>
                            <span className="navbar-toggler-bar middle-bar"></span>
                            <span className="navbar-toggler-bar bottom-bar"></span>
                        </button>
                    </div>
                    <Collapse
                        className="justify-content-end"
                        isOpen={collapseOpen}
                        navbar
                    >
                        <Nav navbar style={{ fontSize: "1.2rem" }}>
                            <NavItem>
                                <NavLink
                                    onClick={(e) => {
                                        window.location.href = "/admin/question";
                                    }}
                                >
                                    <p>Q&A</p>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink disabled={true}>
                                    <p>{email}</p>
                                </NavLink>
                            </NavItem>
                            <CustomButton
                                title="제품 추가"
                                resize={resize}
                                onClick={() => {
                                    window.location.href = "/admin/create";
                                }}
                            />
                            <CustomButton
                                title="로그아웃"
                                resize={resize}
                                onClick={async () => {
                                    await logout();
                                    window.location.href = "/admin";
                                }}
                            />
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </>
    );
}

function toggleCollapse(toggle, value) {
    document.documentElement.classList.toggle("nav-open");
    toggle(value);
}

function CustomButton(props) {
    return (
        <Button
            style={{
                borderRadius: "30px",
                border: "1px solid white",
                backgroundColor: "transparent",
                blockSize: "fit-content",
                marginTop: props.resize < "992px" ? "5px" : "0px",
                marginLeft: props.resize < "992px" ? "0px" : "10px",
                marginRight: props.resize < "992px" ? "0px" : "10px",
            }}
            onClick={props.onClick}
        >
            <p>{props.title}</p>
        </Button>
    );
}

export default AdminIndexNavbar;
