import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getProductFromFirestore } from "services/productService";
import { Instagram } from "react-content-loader";
import ShopEditForm from "./\bShopCreateForm/ShopEditForm";
import AdminDepthNavbar2 from "components/Navbars/AdminDepthNavbar2";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "config/firebase";

const AdminShopDetail = () => {
    const { id } = useParams();
    const [product, setProduct] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    React.useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (authUser) => {
            if (authUser) {
                // 사용자가 인증되어 있을 때
                setUser(authUser);
            } else {
                // 사용자가 인증되어 있지 않을 때
                setUser(null);
                console.log("권한이 없습니다.");
                navigate("/admin"); // 리디렉션을 여기서 수행
            }
        });

        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");

        const fetchData = async () => {
            try {
                const productData = await getProductFromFirestore(id); // getProduct 함수가 비동기로 데이터를 가져오도록 수정
                setProduct(productData);
            } catch (error) {
                console.error("Error fetching product: ", error);
            }
            setLoading(false);
        };

        fetchData();

        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
            unsubscribe(); // 인증 상태 변경 리스너 등록 해제
        };
    }, [id, navigate]);

    return (
        <>
            {user ? (
                <>
                    <AdminDepthNavbar2 />
                    <div style={{ height: "150px" }}></div>

                    {loading ? (
                        <Instagram />
                    ) : (
                        <ShopEditForm product={product} />
                    )}
                </>
            ) : null}
        </>
    );
};

export default React.memo(AdminShopDetail);
