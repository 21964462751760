import { auth, googleProvider } from "../config/firebase";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
} from "firebase/auth";

export async function signUpWithEmail(email, password) {
    try {
        await createUserWithEmailAndPassword(auth, email, password);
    } catch (err) {
        switch (err.code) {
            case "auth/email-already-in-use":
                alert("이미 사용중인 이메일입니다.");
                break;
            case "auth/invalid-email":
                alert("유효하지 않은 이메일입니다.");
                break;
            case "auth/weak-password":
                alert("비밀번호는 6자리 이상이어야 합니다.");
                break;
            default:
                alert(err.code + " 회원가입 실패");
                break;
        }
    }
}
export async function signInWithEmail(email, password) {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        switch (err.code) {
            case "auth/invalid-email":
                alert("유효하지 않은 이메일입니다.");
                break;
            case "auth/wrong-password":
                alert("비밀번호가 틀렸습니다.");
                break;
            case "auth/user-not-found":
                alert("찾을 수 없는 사용자입니다.");
                break;
            default:
                alert(err.code + " 로그인 실패");
                break;
        }
    }
}

export async function adminSignInWithEmail(email, password) {
    try {
        const user = await signInWithEmailAndPassword(auth, email, password);
        console.log(user);
    } catch (err) {
        switch (err.code) {
            case "auth/invalid-email":
                alert("유효하지 않은 이메일입니다.");
                return false;
            case "auth/wrong-password":
                alert("비밀번호가 틀렸습니다.");
                return false;
            case "auth/user-not-found":
                alert("찾을 수 없는 사용자입니다.");
                return false;
            default:
                alert(err.code + " 관리자 로그인 실패");
                return false;
        }
    }
    return true;
}

export async function signInWithGoogle() {
    try {
        await signInWithPopup(auth, googleProvider);
    } catch (err) {
        console.error(err);
    }
}

export async function logout() {
    try {
        await signOut(auth);
    } catch (err) {
        console.error(err);
    }
}
