import DepthNavbar from "components/Navbars/DepthNavbar";
import React from "react";

import "./Consulting.css";
import { Button, Container } from "reactstrap";
import { Divider, Grid } from "@mui/material";

import ConsultingBlock1 from "components/ConsultingBlock/ConsultingBlock1";
import ConsultingBlock2 from "components/ConsultingBlock/ConsultingBlock2";
import ConsultingBlock3 from "components/ConsultingBlock/ConsultingBlock3";
import ConsultingBlock5 from "components/ConsultingBlock/ConsultingBlock5";

const ConsultingPage4 = () => {
    return (
        <>
            <DepthNavbar />
            <div style={{ height: "80px" }}></div>
            <ConsultingSectionPage />
        </>
    );

    function ConsultingSectionPage() {
        return (
            <>
                <div className="consulting-page-header">
                    <div
                        className="consulting-page-header-image"
                        style={{
                            backgroundImage:
                                "url(" +
                                require("assets/img/pictures/consulting4.jpg") +
                                ")",
                        }}
                    >
                        <Container>
                            <div className="consulting-content-center">
                                <h1>물류 및 인력</h1>
                                <br></br>
                                <h3>
                                    THINKLINE을 통한 물류 최적화 및 인력 관리
                                </h3>
                                <br></br>

                                <h5>
                                    <Button
                                        outline
                                        style={{
                                            color: "white",
                                        }}
                                        onClick={() => {
                                            window.location.href =
                                                "/question/create";
                                        }}
                                    >
                                        고객 문의 및 상담
                                    </Button>
                                </h5>
                            </div>
                        </Container>
                    </div>
                </div>
                <div className="consulting-content-body">
                    <Grid container spacing={8}>
                        <Grid item xs={10} md={5}>
                            <div className="consulting-page-body-1">
                                <div className="consulting-page-body-title">
                                    <h1>물류 운영 정의 및 향상</h1>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={10} md={4}>
                            <div className="consulting-page-body-2">
                                <div className="consulting-page-body-content">
                                    <b>THINKLINE</b>는 화물 운송, 복합 운송, 우편 및 특송,
                                    철도 운송, 항공 운송, 트럭 운송 등 다양한
                                    형태의 물류 서비스를 위한 솔루션을
                                    제공합니다. 이러한 솔루션은 장기적인 전략
                                    계획에서 일일 인력 일정에 이르기까지 운영 및
                                    물류 계획을 최적화하여, 배송 성과를
                                    개선하고, 비용을 절감하고, 매출 성장을
                                    증대하며, 연료 소비와 CO2 배출을 최소화할 수
                                    있는 완벽한 가시성과 제어 기능을 제공합니다.
                                    주요 기능으로는 실시간 재최적화, 시나리오
                                    계획, 완벽한 맞춤, 통합 계획, 실시간 가동
                                    중단 처리 등이 있습니다. 또한 이 솔루션은
                                    고유한 비즈니스 제약 사항과 규칙을 통합하고,
                                    최적의 계획을 생성하며, 사용 가능하고
                                    활용되는 모든 자산, 리소스 기능, 제약 사항에
                                    대한 엔드 투 엔드 가시성을 제공합니다.
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    <div style={{ height: "80px" }}></div>

                    <Grid container spacing={8}>
                        <Grid item xs={10} md={5}>
                            <div className="consulting-page-body-1">
                                <div className="consulting-page-body-title">
                                    <h1>인력 관리 최적화</h1>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={10} md={4}>
                            <div className="consulting-page-body-2">
                                <div className="consulting-page-body-content">
                                    <b>THINKLINE</b> 인력 스케줄링은 직원 일정과 계획
                                    기능을 통해 인력 관리를 최적화합니다. 이것은
                                    인력 관리 시스템에 대한 완벽한 가시성과 제어
                                    기능을 제공하여, 계획과 일정 프로세스를 단일
                                    인력 계획 소프트웨어로 통합합니다. 사용자는
                                    근무조 선호 사항, 노동 규정과 같은 고유한
                                    인력 일정 제약 사항을 고려하여 명단을 작성할
                                    수 있습니다. 또한 직원들은 모바일
                                    애플리케이션을 사용하여 교대 근무를
                                    제시/교환하고, 휴가를 요청하고, 선호 근무
                                    시간을 정의할 수 있습니다. 또한, 이 솔루션을
                                    통해 사용자는 장기 인력 배치 계획이 수요를
                                    충족시키고 작업과 작업 주문 할당을
                                    최적화하여 활용도와 효율성을 극대화할 수
                                    있는 방법을 이해할 수 있습니다.
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <Divider />
                <div className="consulting-page-section-title">
                    <h1> 함께 알아보기</h1>
                </div>
                <div className="consulting-page-section-div">
                    <div className="consulting-page-section">
                        <ConsultingBlock1 />
                        <ConsultingBlock2 />
                        <ConsultingBlock3 />
                        <ConsultingBlock5 />
                    </div>
                </div>
            </>
        );
    }
};

export default ConsultingPage4;
