import AdminProductCard from "components/Card/AdminProductCard";
import React, { useState } from "react";
import { List } from "react-content-loader";
import { Col, Container, Row } from "reactstrap";
import { getProductListFromFirestore } from "services/productService";

const AdminShopList = () => {
    const [products, setProducts] = useState([]);
    const [columnSize, setColumnSize] = useState("3");
    const [loading, setLoading] = useState(true);

    React.useEffect(() => {
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");

        const fetchProducts = async () => {
            try {
                const productData = await getProductListFromFirestore();
                setLoading(false);
                setProducts(productData);
            } catch (error) {
                console.error("Error fetching products: ", error);
            }
        };

        fetchProducts();
    }, []);

    React.useEffect(() => {
        // Function to update column size based on window width
        const updateColumnSize = () => {
            if (window.innerWidth < 994) {
                setColumnSize("4");
            } else {
                setColumnSize("3");
            }
        };

        // Add event listener for window resize
        window.addEventListener("resize", updateColumnSize);

        // Initial call to set the column size
        updateColumnSize();

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", updateColumnSize);
        };
    }, []);
    return (
        <Container
            style={{
                marginTop: "50px",
            }}
        >
            {loading ? <List /> : productList()}
        </Container>
    );

    function productList() {
        return (
            <Row>
                {products.map((product) => {
                    return (
                        <Col
                            key={product.id}
                            md={columnSize}
                            style={{
                                marginBottom: "50px",
                            }}
                        >
                            <AdminProductCard product={product} />
                        </Col>
                    );
                })}
            </Row>
        );
    }
};

export default AdminShopList;
