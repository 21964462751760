import DepthNavbar from "components/Navbars/DepthNavbar";
import React from "react";

import "./Consulting.css";
import { Button, Container } from "reactstrap";
import { Divider, Grid } from "@mui/material";

import ConsultingBlock1 from "components/ConsultingBlock/ConsultingBlock1";
import ConsultingBlock3 from "components/ConsultingBlock/ConsultingBlock3";
import ConsultingBlock4 from "components/ConsultingBlock/ConsultingBlock4";
import ConsultingBlock5 from "components/ConsultingBlock/ConsultingBlock5";

const ConsultingPage2 = () => {
    return (
        <>
            <DepthNavbar />
            <div style={{ height: "80px" }}></div>
            <ConsultingSectionPage />
        </>
    );

    function ConsultingSectionPage() {
        return (
            <>
                <div className="consulting-page-header">
                    <div
                        className="consulting-page-header-image"
                        style={{
                            backgroundImage:
                                "url(" +
                                require("assets/img/pictures/consulting2.jpg") +
                                ")",
                        }}
                    >
                        <Container>
                            <div className="consulting-content-center">
                                <h1>산업 엔지니어링</h1>
                                <br></br>
                                <h3>글로벌 운영 계획, 시뮬레이션, 모델링</h3>
                                <br></br>

                                <h5>
                                    <Button
                                        outline
                                        style={{
                                            color: "white",
                                        }}
                                        onClick={() => {
                                            window.location.href =
                                                "/question/create";
                                        }}
                                    >
                                        고객 문의 및 상담
                                    </Button>
                                </h5>
                            </div>
                        </Container>
                    </div>
                </div>
                <div className="consulting-content-body">
                    <Grid container spacing={8}>
                        <Grid item xs={10} md={5}>
                            <div className="consulting-page-body-1">
                                <div className="consulting-page-body-title">
                                    <h1>운영 정의, 모델링, 시뮬레이션</h1>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={10} md={4}>
                            <div className="consulting-page-body-2">
                                <div className="consulting-page-body-content">
                                    스마트 제조란 설계의 영향부터 글로벌 수요를
                                    충족시키는 방법을 결정하고 지속 가능한
                                    프로세스를 구현하는 방법에 이르기까지
                                    처음부터 제대로 설계하는 것을 의미합니다.
                                    지속 가능성은 재생 가능 에너지로 전환하는
                                    것뿐만 아니라 생산 방식의 변화와도
                                    관련됩니다. 배출량의 45%가 제조에서 발생함에
                                    따라 <b>THINKLINE</b> 솔루션은 제조업체가
                                    지속 가능성을 높이고 환경에 미치는 긍정적인
                                    영향을 강화할 수 있도록 지원합니다.{" "}
                                    <b>THINKLINE</b>
                                    산업 엔지니어링은 고객이 지속 가능성 제조
                                    시스템을 만들고 최적화하기 위해 포괄적인 3D
                                    프로세스와 리소스 계획 도구를 사용하여
                                    정확하게 계획하도록 운영을 정의하고 최적화할
                                    수 있도록 지원합니다.{" "}
                                    <b>
                                        <br></br>
                                        <br></br>THINKLINE는
                                    </b>{" "}
                                    광범위한 산업/제조 모델 범위를 제공하여
                                    MTS(Make To Stock), ETO(Engineer to Order),
                                    프로젝트/프로그램 기반, MTS(Make To
                                    Stock)에서 반복, 주문 생산 공장, 배치,
                                    프로세스, 하이브리드 제조에 이르기까지
                                    다양한 제조 모델을 갖춘 9개 산업을 지원하며,
                                    수직적으로 통합된 기업 또는 다각화된 글로벌
                                    제조업체에 전 세계적으로 배포할 수 있습니다.
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    <div style={{ height: "80px" }}></div>

                    <Grid container spacing={8}>
                        <Grid item xs={10} md={5}>
                            <div className="consulting-page-body-1">
                                <div className="consulting-page-body-title">
                                    <h1>정확한 제조 및 생산 시설 운영</h1>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={10} md={4}>
                            <div className="consulting-page-body-2">
                                <div className="consulting-page-body-content">
                                    스마트 제조란 설계의 영향에서 글로벌 수요를
                                    충족시키는 방법을 결정하는 것까지 처음부터
                                    제대로 설계하는 것을 의미합니다.{" "}
                                    <b>THINKLINE</b> 산업 엔지니어링을 통해
                                    고객은 전체 운영을 정의하고 최적화할 수
                                    있습니다. 포괄적인 3D 프로세스와 리소스 계획
                                    도구를 사용해 적절하계 계획하여 주문
                                    생산(BTO)과 린 생산 제조 시스템을 만들고
                                    최적화합니다. 제조 계획에 맞춰 제조 자산을
                                    동시 병행하여 가상으로 정의하고
                                    최적화합니다. <br></br>
                                    <br></br>
                                    <b>THINKLINE</b> 산업 엔지니어링을 통해
                                    정확한 가상 생산 시스템을 운영하여 실시간
                                    생산 활동 추적, 일정 변경 수행, 새로운
                                    프로그램 시작, 모델 전환 도입, 유지 보수
                                    작업 일정 등을 수행할 수 있습니다. 버추얼
                                    트윈을 통해 최적화를 달성하여 실제 환경에서
                                    구현하고 실행할 수 있는 작업을 시연합니다.
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <Divider />
                <div className="consulting-page-section-title">
                    <h1> 함께 알아보기</h1>
                </div>
                <div className="consulting-page-section-div">
                    <div className="consulting-page-section">
                        <ConsultingBlock1 />
                        <ConsultingBlock3 />
                        <ConsultingBlock4 />
                        <ConsultingBlock5 />
                    </div>
                </div>
            </>
        );
    }
};

export default ConsultingPage2;
