import React, { useEffect } from "react";

import AdminIndexNavbar from "components/Navbars/AdminIndexNavbar.js";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "config/firebase.js";
import BannerHeader from "views/index-sections/BannerHeader";
import AdminShopList from "./AdminShopList";

function AdminIndex() {
    const navigate = useNavigate();
    const [user, setUser] = React.useState(null);
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (authUser) => {
            if (authUser) {
                // 사용자가 인증되어 있을 때
                setUser(authUser);
            } else {
                // 사용자가 인증되어 있지 않을 때
                setUser(null);
                console.log("권한이 없습니다.");
                navigate("/admin"); // 리디렉션을 여기서 수행
            }
        });

        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");

        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
            unsubscribe(); // 인증 상태 변경 리스너 등록 해제
        };
    });
    return (
        <>
            {user ? (
                <>
                    <AdminIndexNavbar email={user.email} />
                    <BannerHeader
                        title="Product"
                        description="이용자들이 보는 화면과 같은 상품 리스트를 관리합니다."
                    />
                    <AdminShopList />
                </>
            ) : null}
        </>
    );
}

export default AdminIndex;
